import './style.css';
import React from 'react';
import NavBarButton from '../navBarButton/NavBarButton';
// import LanguageSelect from '../languageSelector/LanguageSelector';
import colors from '../../colors';
import { animated, Spring } from 'react-spring';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

class NavBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.buttons = [
      { path: '/', id: 0, text: 'homepage.label' },

      { path: '/contact', id: 1, text: 'contactpage.label' },
    ];
    const { t } = this.props;
    this.t = t;

    this.state = { idButtonActivated: 0, buttons: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  getButtonActivatedFromUrl() {
    let elementId = 0;
    this.buttons.forEach((element) => {
      if (this.props.router.location.pathname === element.path) {
        elementId = element.id;
      }
    });
    return elementId;
  }

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }

  async componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          width: this.contentRef.current.offsetWidth,
          height: this.contentRef.current.offsetHeight,
        }),
      200
    );
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.setState({ idButtonActivated: this.getButtonActivatedFromUrl() });

    setTimeout(
      () =>
        this.setState({
          width: this.ref.current.offsetWidth,
          height: this.ref.current.offsetHeight,
          animation: true,
        }),
      200
    );
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  changeIdButtonActivated(idButton) {
    this.setState({ idButtonActivated: idButton });
  }

  renderButtons() {
    let buttonsRender = [];

    this.buttons.forEach((element) => {
      buttonsRender.push(
        <NavBarButton
          changeButtonActivated={this.changeIdButtonActivated.bind(this)}
          idButton={element.id}
          link={element.path}
          activated={element.id === this.state.idButtonActivated ? true : false}
          text={this.t(element.text)}
          key={element.id}
        ></NavBarButton>
      );
    });
    return buttonsRender;
  }

  render() {
    return (
      <Spring marginBottom={this.state.buttons ? 140 : 0}>
        {(styles) => (
          <animated.div
            style={{
              ...styles,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <nav className='navigation'>
              <a href='/' className='brand-name'>
                <div
                  style={{
                    display: 'flex',
                    height: this.state.height,
                    width: this.state.width,
                    position: 'relative',
                  }}
                >
                  <Spring
                    bottom={this.state.animation ? 8 : 0}
                    left={this.state.animation ? 8 : 0}
                  >
                    {(styles) => (
                      <animated.div
                        style={{
                          ...styles,
                          position: 'absolute',
                          backgroundColor: colors.blue,

                          height: this.state.height,
                          width: this.state.width,
                          borderRadius: 10,
                        }}
                      ></animated.div>
                    )}
                  </Spring>
                  <div
                    ref={this.ref}
                    style={{
                      top: 0,
                      left: 0,
                      position: 'absolute',

                      backgroundColor: colors.black,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderRadius: 10,
                    }}
                  >
                    <img
                      alt='Texte Radiant'
                      style={{ height: '38px' }}
                      src={require('../../assets/typo_white.webp')}
                    />
                  </div>
                </div>
              </a>

              <div
                className='navigation-menu'
                style={{
                  display: 'flex',
                  justifyContent:
                    this.state.windowWidth < 600 ? 'flex-end' : 'space-around',
                  alignItems: 'center',
                }}
              >
                {this.state.windowWidth >= 600 ? (
                  this.renderButtons()
                ) : (
                  <Spring
                    transform={
                      this.state.buttons ? 'rotate(90deg)' : 'rotate(0deg)'
                    }
                  >
                    {(styles) => (
                      <animated.img
                        onClick={() =>
                          this.setState({ buttons: !this.state.buttons })
                        }
                        style={{
                          ...styles,
                          width: '20%',
                        }}
                        src={require('../../assets/suite.png')}
                      ></animated.img>
                    )}
                  </Spring>
                )}
              </div>
            </nav>
            <Spring opacity={this.state.buttons ? 1 : 0}>
              {(styles) => (
                <animated.div
                  style={{
                    ...styles,
                    position: 'absolute',
                    marginBottom: 0,
                    top: 80,
                    left: 0,
                  }}
                >
                  {this.renderButtons()}
                </animated.div>
              )}
            </Spring>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export default withRouter(withTranslation()(NavBarComponent));
