import React from 'react';
import { animated, Spring } from 'react-spring';
import colors from '../../colors';
import { withTranslation } from 'react-i18next';
class FactCarroussel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
      selected: 0,
    };
    this.facts = [
      {
        text: 'carr2.label',
        image: require('../../assets/facts/Edmond_de_Belamy.webp'),
      },
      {
        text: 'carr1.label',
        image: require('../../assets/facts/midjourney.webp'),
      },
      {
        text: 'carr3.label',
        image: require('../../assets/facts/tesla-autopilot.webp'),
      },
    ];
    this.updateSelected = this.updateSelected.bind(this);
    this.interval = null;
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    const t = this.props.t;
    this.t = t;
  }

  updateSelected() {
    let selected = this.state.selected;
    if (selected === this.facts.length - 1) {
      selected = 0;
    } else {
      selected += 1;
    }
    this.setState({ selected: selected });
  }

  componentDidMount() {
    if (this.interval === null) {
      this.interval = setInterval(() => {
        this.updateSelected();
      }, 2000);
    }
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth * 0.98,
      windowHeight: window.innerHeight,
    });
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  renderPoints() {
    let points = [];
    let i = 0;
    this.facts.forEach((element) => {
      points.push(
        <Spring
          key={'element_' + i}
          id={i + '_points'}
          backgroundColor={
            i === this.state.selected ? colors.yellow : colors.purple
          }
          width={i === this.state.selected ? 15 : 10}
          height={i === this.state.selected ? 15 : 10}
          borderRadius={i === this.state.selected ? 15 / 2 : 10 / 2}
        >
          {(styles) => (
            <animated.div
              style={{
                ...styles,
                margin: 3,
              }}
            ></animated.div>
          )}
        </Spring>
      );
      i = i + 1;
    });
    return points;
  }

  renderImages() {
    let images = [];
    let i = 0;
    this.facts.forEach((element) => {
      images.push(
        <Spring
          key={'image_' + i}
          opacity={this.state.selected === i ? 1 : 0}
          id={i + '_images'}
        >
          {(styles) => (
            <animated.img
              src={element.image}
              alt='Carroussel actualités'
              style={{
                ...styles,
                position: 'absolute',
                width: '100%',
              }}
            ></animated.img>
          )}
        </Spring>
      );
      i = i + 1;
    });
    return images;
  }

  render() {
    return (
      <div
        style={{
          width: this.state.windowWidth,
          height: 500,
          overflow: 'hidden',
        }}
      >
        <div style={{ position: 'relative' }}>
          {this.renderImages()}

          <h3
            style={{
              position: 'absolute',
              color: 'white',
              left: '2%',
              backgroundColor: colors.purple,
              borderRadius: 10,
              fontFamily: 'Goldman_medium',
              fontSize: this.state.windowWidth < 600 ? 'medium' : 'large',
            }}
          >
            {this.t(this.facts[this.state.selected].text)}
          </h3>
          <div
            style={{
              display: 'flex',
              position: 'absolute',

              left: this.state.windowWidth / 2,

              top: 450,
              marginLeft: 'auto',
              marginLight: 'auto',
              transform: 'translate(-50%,-70%)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {this.renderPoints()}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FactCarroussel);
