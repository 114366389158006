import React from "react";
import colors from "../../colors";
import HighLightTextComponent from "../highlightText/hightLightTextComponent";
import ImageComponent from "../imageComponent/ImageComponent";
import { withTranslation } from "react-i18next";
class TeamDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
      image1: false,
      image2: false,
      image3: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    const { t } = this.props;
    this.t = t;
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          marginTop: 50,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: "auto",
            }}
          >
            <HighLightTextComponent
              text={this.t("founders.label")}
              highLightIdx="1"
              textColor={colors.black}
              bgColor={colors.purple}
            ></HighLightTextComponent>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
            height: "100%",
            marginBottom: "0%",
            marginLeft: "3%",
            marginRight: "%",
            marginTop: "6%",
            flexDirection: this.state.windowWidth < 600 ? "column" : "row",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <ImageComponent
            ref={this.imageRef1}
            animation={this.state.image1}
            path={require("../../assets/photo_charly.jpg")}
            path2={require("../../assets/photo_charly.jpg")}
            fade1={colors.purple}
            fade2={colors.blue}
            width={
              this.state.windowWidth < 600
                ? this.state.windowWidth * 0.4
                : this.state.windowWidth * 0.15
            }
            ratio="1"
            animationOnScroll={this.state.windowWidth < 600}
          ></ImageComponent>
          <div
            style={{
              margin: 10,
              marginLeft: this.state.windowWidth < 600 ? 0 : 50,
              textAlign: this.state.windowWidth < 600 ? "center" : "unset",
            }}
          >
            <h3
              style={{
                fontFamily: "Goldman_medium",
                fontSize: this.state.windowWidth < 600 ? "medium" : "large",
              }}
            >
              Charly Malamaire
              <a
                href="https://www.linkedin.com/in/charly-malamaire-59b776151/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Icone Linkedin"
                  style={{
                    width: "1em",
                    height: "1em",
                    paddingLeft: "5%",
                    position: "relative",
                    top: "3px",
                  }}
                  src={require("../../assets/icone_lk.webp")}
                ></img>
              </a>
            </h3>
            <span
              style={{
                textAlign: this.state.windowWidth < 600 ? "center" : "unset",
                fontFamily: "Goldman_medium",
              }}
            >
              {this.t("position1.label")}
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
            height: "100%",
            marginBottom: "3%",
            marginLeft: "3%",
            marginRight: "3%",
            marginTop: "6%",
            flexDirection: this.state.windowWidth < 600 ? "column" : "row",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          {this.state.windowWidth >= 600 && (
            <div style={{ margin: 10, marginRight: 50 }}>
              <h3>
                Jean Hastoy
                <a
                  href="https://www.linkedin.com/in/jeanhastoy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Icone Linkedin"
                    style={{
                      width: "1em",
                      height: "1em",
                      paddingLeft: "5%",
                      position: "relative",
                      top: "3px",
                    }}
                    src={require("../../assets/icone_lk.webp")}
                  ></img>
                </a>
              </h3>
              <span style={{ fontFamily: "Goldman_medium" }}>
                {this.t("position1.label")}
              </span>
            </div>
          )}
          <ImageComponent
            ref={this.imageRef2}
            animation={this.state.image2}
            path={require("../../assets/photo_jean.jpg")}
            path2={require("../../assets/photo_jean.jpg")}
            fade1={colors.blue}
            fade2={colors.yellow}
            width={
              this.state.windowWidth < 600
                ? this.state.windowWidth * 0.4
                : this.state.windowWidth * 0.15
            }
            ratio="1"
            animationOnScroll={this.state.windowWidth < 600}
          ></ImageComponent>
          {this.state.windowWidth < 600 && (
            <div
              style={{
                margin: 10,
                textAlign: this.state.windowWidth < 600 ? "center" : "unset",
              }}
            >
              <h3
                style={{
                  fontFamily: "Goldman_medium",
                  fontSize: this.state.windowWidth < 600 ? "medium" : "large",
                }}
              >
                Jean Hastoy
                <a
                  href="https://www.linkedin.com/in/jeanhastoy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Icone Linkedin"
                    style={{
                      width: "1em",
                      height: "1em",
                      paddingLeft: "5%",
                      position: "relative",
                      top: "3px",
                    }}
                    src={require("../../assets/icone_lk.webp")}
                  ></img>
                </a>
              </h3>
              <span
                style={{
                  textAlign: this.state.windowWidth < 600 ? "center" : "unset",
                  fontFamily: "Goldman_medium",
                }}
              >
                {this.t("position1.label")}
              </span>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
            height: "100%",
            marginBottom: "0%",
            marginLeft: "3%",
            marginRight: "%",
            marginTop: "6%",
            flexDirection: this.state.windowWidth < 600 ? "column" : "row",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <ImageComponent
            ref={this.imageRef3}
            animation={this.state.image3}
            path={require("../../assets/photo_corentin.jpg")}
            path2={require("../../assets/photo_corentin.jpg")}
            fade1={colors.yellow}
            fade2={colors.blue}
            width={
              this.state.windowWidth < 600
                ? this.state.windowWidth * 0.4
                : this.state.windowWidth * 0.15
            }
            ratio="1"
            animationOnScroll={this.state.windowWidth < 600}
          ></ImageComponent>
          <div
            style={{
              margin: 10,
              marginLeft: this.state.windowWidth < 600 ? 0 : 50,

              width: "80%",
              textAlign: this.state.windowWidth < 600 ? "center" : "unset",
            }}
          >
            <h3
              style={{
                textAlign: this.state.windowWidth < 600 ? "center" : "unset",
                fontFamily: "Goldman_medium",
                fontSize: this.state.windowWidth < 600 ? "medium" : "large",
              }}
            >
              Corentin Morvan-Chaumeil
              {/* <a
                href="https://www.linkedin.com/in/quentin-zimmermann-viasatconnect/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Icone Linkedin"
                  style={{
                    width: "1em",
                    height: "1em",
                    paddingLeft: "5%",
                    position: "relative",
                    top: "3px",
                  }}
                  src={require("../../assets/icone_lk.webp")}
                ></img>
              </a> */}
            </h3>
            <span
              style={{
                textAlign: this.state.windowWidth < 600 ? "center" : "unset",
                fontFamily: "Goldman_medium",
              }}
            >
              {this.t("position2.label")}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(TeamDivComponent);
