import React from 'react';
import { animated, Spring } from 'react-spring';
import colors from '../../colors';
import { PopupButton } from 'react-calendly';
<script
  type='text/javascript'
  src='https://assets.calendly.com/assets/external/widget.js'
></script>;
class ButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
    };
    this.ref = React.createRef();
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({
      width: this.ref.current.getBoundingClientRect().width,
      height: this.ref.current.getBoundingClientRect().height,
    });
  }
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);
    this.updateDimensions();
    window.addEventListener('load', this.updateDimensions);
  }

  componentDidUpdate() {
    if (this.ref.current.getBoundingClientRect().width !== this.state.width) {
      this.setState({
        width: this.ref.current.getBoundingClientRect().width,
        height: this.ref.current.getBoundingClientRect().height,
      });
    }
    if (this.ref.current.getBoundingClientRect().height !== this.state.height) {
      this.setState({
        width: this.ref.current.getBoundingClientRect().width,
        height: this.ref.current.getBoundingClientRect().height,
      });
    }
  }
  render() {
    return (
      <div
        id='root'
        style={{
          position: 'relative',
          width: '100%',
          height: this.state.height,
          marginTop: '30',
          cursor: 'pointer',
        }}
        onMouseOver={() =>
          this.setState({
            animation: true,
          })
        }
        onMouseOut={() => this.setState({ animation: false })}
      >
        <Spring
          bottom={this.state.animation ? 6 : 0}
          left={this.state.animation ? 6 : 0}
          backgroundColor={this.state.animation ? colors.purple : colors.blue}
        >
          {(styles) => (
            <animated.div
              style={{
                ...styles,
                width: this.state.width,
                height: this.state.height,
                position: 'absolute',
                borderRadius: '10px',
              }}
            ></animated.div>
          )}
        </Spring>
        <div
          ref={this.ref}
          style={{
            position: 'absolute',
            display: 'flex',
            backgroundColor: this.props.color,
            bottom: 0,
            left: 0,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
            boxSizing: 'border-box',
            cursor: 'pointer',
          }}
        >
          <PopupButton
            className=''
            iframeTitle='Page Calendly'
            height='100%'
            pageSettings={{
              hideEventTypeDetails: false,
              hideGdprBanner: true,
              hideLandingPageDetails: false,
            }}
            rootElement={document.getElementById('root')}
            styles={{
              display: 'flex',
              margin: '0%',
              marginTop: 10,
              marginBottom: 10,
              backgroundColor: 'transparent',
              justifyContent: 'center',
              alignItems: 'center',
              color: colors.black,
              border: '0px',
              fontFamily: 'Goldman_bold',
              fontSize: 'x-large',
              cursor: 'pointer',
              textAlign: 'center',
            }}
            text={this.props.text}
            url='https://calendly.com/radiant-schedule'
          />
        </div>
      </div>
    );
  }
}

export default ButtonComponent;
