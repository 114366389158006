import React from 'react';
import { animated, Spring } from 'react-spring';
import colors from '../../colors';
import { Link } from 'react-router-dom';

class MapDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
    };
    this.contentRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  updateDimensions() {
    this.setState({
      width: this.contentRef.current.getBoundingClientRect().width,
      height: this.contentRef.current.getBoundingClientRect().height,
    });
  }
  handleScroll(offset = 0) {
    if (this.state.windowWidth < 600) {
      if (!this.contentRef.current) return false;
      const top = this.contentRef.current.getBoundingClientRect().top;

      if (top + offset >= 0 && top - offset <= window.innerHeight) {
        this.setState({ animation: true });
      }
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();

    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', () => this.handleScroll());
  }

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }

  componentDidUpdate() {
    if (window.outerWidth !== this.state.windowWidth) {
      this.setState({ windowWidth: window.outerWidth });
    }
    if (
      this.contentRef.current.getBoundingClientRect().width !==
        this.state.width ||
      this.contentRef.current.getBoundingClientRect().height !==
        this.state.height
    ) {
      this.setState({
        width: this.contentRef.current.getBoundingClientRect().width,
        height: this.contentRef.current.getBoundingClientRect().height,
      });
    }
  }

  render() {
    return (
      <div
        ref={this.contentRef}
        style={{
          borderRadius: 10,
          display: 'grid',
          gridTemplateColumns: '1fr',
        }}
        onMouseOver={() =>
          this.setState({
            width: this.contentRef.current.offsetWidth,
            height: this.contentRef.current.offsetHeight,
            animation: true,
          })
        }
        onMouseOut={() =>
          this.state.windowWidth >= 600 && this.setState({ animation: false })
        }
      >
        <div style={{ position: 'relative' }}>
          <Spring
            top={this.state.animation ? -8 : 0}
            left={this.state.animation ? 8 : 0}
            backgroundColor={this.state.animation ? '#ffe82c' : '#b599db'}
          >
            {(styles) => (
              <animated.div
                style={{
                  ...styles,
                  height: this.state.height,
                  width: this.state.width,
                  position: 'absolute',
                  borderRadius: 10,
                }}
              ></animated.div>
            )}
          </Spring>
        </div>
        <div
          style={{
            display: 'flex',
            width:
              this.state.windowWidth < 600
                ? 0.94 * this.state.windowWidth
                : 0.8 * this.state.windowWidth,
            color: 'white',
            borderRadius: 10,
            backgroundColor: colors.black,
            alignItems: 'center',
            padding: '5%',
            paddingLeft: '12%',
            paddingRight: '8%',
            boxSizing: 'border-box',
            justifyContent: 'center',
            flexDirection: 'column',
            zIndex: 1000,
          }}
        >
                    <div
          style={{
            display: 'flex',
            width:'100%',
            justifyContent:'center'
          }}
        >
              <h3 >Plan du site</h3></div>

          <ul>
                <Link to="/"><li>Accueil </li></Link>
                 
                <Link to="/projects"> <li>Réalisations </li></Link>
                 
                <Link to="/contact"> <li>Contact</li></Link>
                 
                <Link to="/legal"> <li>Mentions légales </li></Link>
                 
                <Link to="/map"> <li>Plan du site </li></Link>
              </ul>
            
       </div>
      </div>
    );
  }
}

export default MapDivComponent;
