import React from 'react';
import { animated, Spring } from 'react-spring';
import colors from '../../colors';
import { withTranslation } from 'react-i18next';
class TranslateDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
    };
    this.contentRef = React.createRef();
    const t = this.props.t;
    this.t = t;
  }

  isInViewport(offset = 0) {
    if (!this.contentRef.current) return false;
    const top = this.contentRef.current.getBoundingClientRect().top;

    if (top + offset >= 0 && top - offset <= window.innerHeight) {
      this.setState({ animation: true });
    }
  }

  componentDidMount() {
    this.isInViewport();
    window.addEventListener('scroll', () => this.isInViewport());
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.isInViewport());
  }

  padding() {
    if (this.props.inverse) {
      return {
        paddingRight: this.props.width,
      };
    } else {
      return { paddingLeft: this.props.width };
    }
  }

  generateElements() {
    let elementsRender = [];
    this.props.elements.forEach((element) => {
      elementsRender.push(
        <div
          key={element.text}
          style={{
            display: 'flex',
            flex: 1,
            height: this.props.mobile ? '33%' : '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: this.props.mobile ? 'center' : 'flex-end',
              alignItems: 'center',
              height: '60%',
            }}
          >
            {this.state.animation && (
              <img
                alt='Etapes de la réalisation'
                style={{
                  height: 70,
                }}
                src={element.icon}
              ></img>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '40%',
            }}
          >
            {this.state.animation && (
              <span
                style={{
                  textAlign: 'center',
                  color: this.props.textColor,
                  fontFamily: 'Obvia_medium',
                  fontSize: 'small',
                }}
              >
                {element.text}
              </span>
            )}
          </div>
        </div>
      );
    });
    return elementsRender;
  }

  render() {
    return (
      <div
        onClick={() => {}}
        style={{
          position: 'relative',
          borderRadius: 10,
          width: this.props.totalWidth,
          height: this.props.height,
        }}
      >
        <Spring
          top={this.state.animation ? -8 : 0}
          left={
            this.props.mobile
              ? this.state.animation
                ? this.props.inverse
                  ? 0
                  : 8
                : this.props.inverse
                ? this.props.totalWidth - this.props.width
                : 0
              : this.state.animation
              ? this.props.inverse
                ? 8 + this.props.width
                : 8
              : this.props.inverse
              ? this.props.totalWidth - this.props.width
              : 0
          }
          backgroundColor={
            this.state.animation
              ? this.props.colorFadeOut
              : this.props.colorFadeIn
          }
          width={
            !this.props.mobile
              ? this.state.animation
                ? this.props.totalWidth - 2 * this.props.width
                : 0
              : this.state.animation
              ? this.props.inverse
                ? this.props.totalWidth - this.props.width + 8
                : this.props.totalWidth - this.props.width - 8
              : 0
          }
        >
          {(styles) => (
            <animated.div
              className={this.props.gradient}
              style={{
                ...this.padding(),
                ...styles,
                display: 'flex',
                height: this.props.height,
                position: 'absolute',
                borderRadius: 10,
                flexDirection: this.props.mobile ? 'column' : 'row',
              }}
            >
              {this.generateElements()}
            </animated.div>
          )}
        </Spring>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <div
            ref={this.contentRef}
            style={{
              display: 'flex',

              position: 'absolute',
              top: 0,
              left: this.props.inverse
                ? this.props.totalWidth - this.props.width
                : 0,
              width: this.props.width,
              height: this.props.height,

              color: 'white',
              borderRadius: 10,
              backgroundColor: colors.black,
              alignItems: 'center',
              flexDirection: 'column',

              boxSizing: 'border-box',
              justifyContent: 'center',
            }}
          >
            <h3
              style={{
                fontFamily: 'Goldman_medium',
                fontSize: this.state.windowWidth < 600 ? 'medium' : 'large',
                textAlign: 'center',
                transform: this.props.mobile
                  ? this.props.inverse
                    ? 'rotate(90deg)'
                    : 'rotate(-90deg)'
                  : '',
                margin: 10,
              }}
            >
              {this.props.title}
            </h3>
          </div>
        </div>
        {/* <Spring
          left={
            this.state.animation
              ? this.props.inverse
                ? 8 + this.props.width
                : this.props.totalWidth - this.props.width
              : this.props.inverse
              ? this.props.totalWidth - this.props.width
              : 0
          }
        >
          {(styles) => (
            <animated.div
              style={{
                ...styles,
                transform: 'translate(-50%,-70%)',
                backgroundColor: colors.yellow,
                top: this.props.height,
                display: 'flex',
                position: 'absolute',
                borderRadius: 10,
              }}
            >
              <span
                style={{
                  color: colors.black,
                  cursor: 'pointer',
                  zIndex: '-1',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => {}}
              >
                {this.t('km.label')}
              </span>
            </animated.div>
          )}
        </Spring> */}
      </div>
    );
  }
}

export default withTranslation()(TranslateDivComponent);
