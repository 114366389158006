import React from "react";
import BlackDivComponent from "../components/blackDiv/BlackDivComponent";
import EthicsDivComponent from "../components/ethicsDiv/EthicsDivComponent";
import WhiteDivComponent from "../components/whiteDiv/whiteDivComponent";
import FactCarroussel from "../components/factsCarroussel/factCarroussel";
import TitleDivComponent from "../components/titleDivComponent/titleDivComponent";
import ContactDivComponent from "../components/contactDiv/ContactDivComponent";
import TeamDivComponent from "../components/teamDiv/TeamDivComponent";
import FooterDivComponent from "../components/footerDiv/FooterDivComponent";
import StatsDivComponent from "../components/statsDiv/StatsDivComponent";
import colors from "../colors";
import FinanceDivComponent from "../components/financeDivComponent/financeDivComponent";
import SkillsDivComponent from "../components/skillsDivComponent/SkillsDivComponent";
import { withTranslation } from "react-i18next";

class HomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    const { t } = this.props;
    this.t = t;
  }

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    return (
      <div className="container">
        <div
          style={{ maxWidth: this.state.windowWidth < 600 ? "94%" : "80%" }}
          className="rowContainer"
        >
          <TitleDivComponent></TitleDivComponent>
        </div>
        <div
          style={{ maxWidth: this.state.windowWidth < 600 ? "94%" : "80%" }}
          className="rowContainer"
        >
          <StatsDivComponent></StatsDivComponent>
        </div>
        <div
          style={{ maxWidth: this.state.windowWidth < 600 ? "94%" : "80%" }}
          className="rowContainer"
        >
          <BlackDivComponent></BlackDivComponent>
        </div>
        <div
          style={{
            maxWidth: this.state.windowWidth < 600 ? "94%" : "80%",
          }}
          className="rowContainer"
        >
          <SkillsDivComponent></SkillsDivComponent>
        </div>

        <div
          style={{ maxWidth: this.state.windowWidth < 600 ? "94%" : "80%" }}
          className="rowContainer"
        >
          <WhiteDivComponent></WhiteDivComponent>
        </div>
        <div className="rowContainerFullWidth">
          <FactCarroussel windowWidth={this.state.windowWidth}></FactCarroussel>
        </div>
        <div
          style={{ maxWidth: this.state.windowWidth < 600 ? "94%" : "80%" }}
          className="rowContainer"
        >
          <FinanceDivComponent></FinanceDivComponent>
        </div>
        <div
          style={{ maxWidth: this.state.windowWidth < 600 ? "94%" : "80%" }}
          className="rowContainer"
        >
          <EthicsDivComponent></EthicsDivComponent>
        </div>
        <div
          style={{ maxWidth: this.state.windowWidth < 600 ? "94%" : "80%" }}
          className="rowContainer"
        >
          <TeamDivComponent></TeamDivComponent>
        </div>
        <div
          style={{ maxWidth: this.state.windowWidth < 600 ? "94%" : "80%" }}
          className="rowContainer"
        >
          <ContactDivComponent></ContactDivComponent>
        </div>
        <h3
          style={{
            fontSize: 14,
            color: colors.black,
            fontFamily: "Goldman_medium",
            marginLeft: 10,
          }}
        >
          {this.t("study.label")}
          <a
            target="blank"
            href="https://www.accenture.com/fr-fr/_acnmedia/36dc7f76eab444cab6a7f44017cc3997.pdf"
          >
            {this.t("study2.label")}
          </a>{" "}
          {this.t("study3.label")}
        </h3>

        <div className="rowContainerFullWidth">
          <FooterDivComponent
            windowWidth={this.state.windowWidth}
          ></FooterDivComponent>
        </div>
      </div>
    );
  }
}

export default withTranslation()(HomeScreen);
