import React from "react";
import { animated, Spring } from "react-spring";
import colors from "../../colors";
import ButtonComponent from "../button/buttonComponent";
import HighLightTextComponent from "../highlightText/hightLightTextComponent";
import ImageComponent from "../imageComponent/ImageComponent";
import "../../App.css";
import ProjectDivComponent from "../projectDiv/ProjectDivComponent";
import { withTranslation } from "react-i18next";
const data = [
  {
    mainLogo: require("../../assets/projects/upside_1/logo_png.png"),
    pathAnim1: require("../../assets/projects/upside_1/logo.png"),

    pathAnim2: require("../../assets/projects/upside_1/screen.png"),
    mainTitle: "proj8.label",
    name: "name2.label",
    colorAnim1: colors.purple,
    colorAnim2: colors.blue,
    citation:
      "test djjdjd ududh udud huh u duhduh dudu udhu dhud uh du hdudh ud uh duhd uhd u dhudh u hdud hu dh",
    profileImg: require("../../assets/projects/rozzos/profil.png"),
    mainTags: [
      { text: "tag51.label", color: colors.purple },
      { text: "tag52.label", color: colors.blue },
      { text: "tag50.label", color: colors.blue },
    ],
    subProjects: [
      {
        title: "proj8t1.label",
        list: ["proj81.label", "proj82.label", "proj83.label", "proj84.label"],
        tags: [],
        image: require("../../assets/projects/upside_1/screen.png"),
        video: "",
      },
    ],
  },
  {
    pathAnim1: require("../../assets/projects/carlab_damages/original.webp"),
    pathAnim2: require("../../assets/projects/carlab_damages/processed.webp"),
    colorAnim1: colors.blue,
    colorAnim2: colors.purple,
    name: "name1.label",

    mainTitle: "proj1.label",
    mainLogo: require("../../assets/projects/carlab_damages/logo.png"),
    citation:
      "L’équipe Radiant nous a permis de blablablablabala knkdndkcnk nkdnksdsn kssnsnn sks nsn knknsksn ss sns nsksn skn n si dhihdnnd idi iddiididj diidhihdihidh idj,j bdjjbhbjlhs",
    profileImg: require("../../assets/projects/carlab_damages/profil.jpg"),
    mainTags: [
      { text: "tag1.label", color: colors.purple },
      { text: "tag2.label", color: colors.purple },
    ],
    subProjects: [
      {
        title: "proj1t1.label",
        list: ["proj11.label", "proj12.label", "proj13.label", "proj14.label"],
        tags: [
          { text: "tag3.label", color: colors.blue },
          { text: "tag4.label", color: colors.purple },
        ],
        image: require("../../assets/projects/carlab_damages/processed.webp"),
        video: "",
      },
      {
        title: "proj1t2.label",
        list: ["proj15.label", "proj16.label", "proj17.label"],
        tags: [
          { text: "tag5.label", color: colors.blue },
          { text: "tag6.label", color: colors.purple },
        ],
        image: require("../../assets/projects/carlab_damages/damages.jpg"),
        video: "",
      },
      {
        title: "proj1t3.label",
        list: [
          "proj18.label",
          "proj19.label",
          "proj110.label",
          "proj111.label",
        ],
        tags: [
          { text: "tag7.label", color: colors.blue },
          { text: "tag8.label", color: colors.blue },
          { text: "tag9.label", color: colors.blue },
        ],
        image: "",
        video: "",
      },
      {
        title: "proj1t4.label",
        list: ["proj112.label", "proj113.label", "proj114.label"],
        tags: [
          { text: "tag10.label", color: colors.blue },
          { text: "tag11.label", color: colors.blue },
        ],
        image: require("../../assets/projects/carlab_damages/frontend.jpg"),
        video: "",
      },
    ],
  },
  {
    pathAnim1: require("../../assets/projects/carlab_marketing/original.webp"),
    pathAnim2: require("../../assets/projects/carlab_marketing/processed.webp"),
    colorAnim1: colors.purple,
    colorAnim2: colors.blue,
    name: "name1.label",

    mainTitle: "proj2.label",
    mainLogo: require("../../assets/projects/carlab_damages/logo.png"),
    citation:
      "L’équipe Radiant nous a permis de blablablablabala knkdndkcnk nkdnksdsn kssnsnn sks nsn knknsksn ss sns nsksn skn n si dhihdnnd idi iddiididj diidhihdihidh idj,j bdjjbhbjlhs",
    profileImg: require("../../assets/projects/carlab_damages/profil.jpg"),
    mainTags: [
      { text: "tag12.label", color: colors.purple },
      { text: "tag13.label", color: colors.purple },
      { text: "tag14.label", color: colors.purple },
    ],
    subProjects: [
      {
        title: "proj2t1.label",
        list: [
          "proj21.label",
          "proj22.label",
          "proj23.label",
          "proj24.label",
          "proj25.label",
        ],
        tags: [
          { text: "tag15.label", color: colors.blue },
          { text: "tag16.label", color: colors.purple },
          { text: "tag17.label", color: colors.purple },

          { text: "tag39.label", color: colors.blue },
        ],
        image: "",
        video: require("../../assets/projects/carlab_marketing/carlab_background_delete.mp4"),
      },
      {
        title: "proj2t2.label",
        list: ["proj26.label", "proj27.label", "proj28.label"],
        tags: [
          { text: "tag18.label", color: colors.blue },
          { text: "tag19.label", color: colors.purple },
        ],
        image: require("../../assets/projects/carlab_marketing/license_plate.jpg"),
        video: "",
      },
      {
        title: "proj2t3.label",
        list: ["proj29.label", "proj210.label"],
        tags: [
          { text: "tag20.label", color: colors.blue },
          { text: "tag21.label", color: colors.purple },
          { text: "tag22.label", color: colors.blue },
        ],
        image: require("../../assets/projects/carlab_marketing/autoshoot.jpg"),
        video: "",
      },
    ],
  },
  {
    colorAnim1: colors.purple,
    colorAnim2: colors.blue,
    pathAnim1: require("../../assets/projects/cariku/original.png"),
    pathAnim2: require("../../assets/projects/cariku/original.png"),
    mainTitle: "proj3.label",
    mainLogo: require("../../assets/projects/cariku/original.png"),
    name: "name2.label",

    citation: "",
    profileImg: "",
    mainTags: [
      { text: "tag23.label", color: colors.purple },
      { text: "tag24.label", color: colors.purple },
      { text: "tag25.label", color: colors.purple },
    ],
    subProjects: [
      {
        title: "proj3t1.label",
        list: ["proj31.label", "proj32.label", "proj33.label"],
        tags: [
          { text: "tag26.label", color: colors.blue },
          { text: "tag27.label", color: colors.purple },
        ],
        video: "",
        image: require("../../assets/projects/cariku/france.png"),
      },
      {
        title: "proj3t2.label",
        list: ["proj34.label", "proj35.label"],
        tags: [
          { text: "tag28.label", color: colors.blue },
          { text: "tag29.label", color: colors.purple },
        ],
        video: "",

        image: require("../../assets/projects/cariku/car.png"),
      },
      {
        title: "proj3t3.label",
        list: ["proj36.label", "proj37.label"],
        tags: [
          { text: "tag30.label", color: colors.blue },
          { text: "tag31.label", color: colors.purple },
        ],
        video: "",

        image: require("../../assets/projects/cariku/chatbot.png"),
      },
    ],
  },
  {
    mainLogo: require("../../assets/projects/rozzos/logo.png"),
    pathAnim1: require("../../assets/projects/rozzos/original.webp"),
    pathAnim2: require("../../assets/projects/rozzos/processed.webp"),
    mainTitle: "proj4.label",
    name: "name2.label",
    colorAnim1: colors.purple,
    colorAnim2: colors.blue,
    citation:
      "test djjdjd ududh udud huh u duhduh dudu udhu dhud uh du hdudh ud uh duhd uhd u dhudh u hdud hu dh",
    profileImg: require("../../assets/projects/rozzos/profil.png"),
    mainTags: [
      { text: "tag32.label", color: colors.purple },
      { text: "tag33.label", color: colors.purple },
    ],
    subProjects: [
      {
        title: "proj4t1.label",
        list: ["proj41.label", "proj42.label", "proj43.label"],
        tags: [
          { text: "tag34.label", color: colors.blue },
          { text: "tag35.label", color: colors.purple },
          { text: "tag36.label", color: colors.blue },
        ],
        image: require("../../assets/projects/rozzos/plateforme.png"),
        video: "",
      },
      {
        mainLogo: require("../../assets/projects/carlab_damages/logo.png"),
        title: "proj4t2.label",
        list: ["proj44.label", "proj45.label"],
        tags: [
          { text: "tag37.label", color: colors.purple },
          { text: "tag38.label", color: colors.purple },
        ],
        image: require("../../assets/projects/rozzos/processed.webp"),
        video: "",
      },
    ],
  },
  {
    mainLogo: require("../../assets/projects/dragon_sourcing/logo_png.png"),
    pathAnim1: require("../../assets/projects/dragon_sourcing/logo.png"),
    pathAnim2: require("../../assets/projects/dragon_sourcing/logo.png"),
    mainTitle: "proj5.label",
    name: "name2.label",
    colorAnim1: colors.purple,
    colorAnim2: colors.blue,
    citation:
      "test djjdjd ududh udud huh u duhduh dudu udhu dhud uh du hdudh ud uh duhd uhd u dhudh u hdud hu dh",
    profileImg: require("../../assets/projects/rozzos/profil.png"),
    mainTags: [
      { text: "tag23.label", color: colors.purple },
      { text: "tag50.label", color: colors.purple },
    ],
    subProjects: [
      {
        title: "proj5t1.label",
        list: ["proj51.label", "proj52.label", "proj53.label"],
        tags: [
          { text: "tag34.label", color: colors.blue },
          { text: "tag35.label", color: colors.purple },
          { text: "tag36.label", color: colors.blue },
          { text: "tag50.label", color: colors.purple },
        ],
        image: require("../../assets/projects/rozzos/plateforme.png"),
        video: "",
      },
    ],
  },
  {
    mainLogo: require("../../assets/projects/choisir/logo_png.png"),
    pathAnim1: require("../../assets/projects/choisir/choisir.png"),
    pathAnim2: require("../../assets/projects/choisir/choisir.png"),
    mainTitle: "proj6.label",
    name: "name2.label",
    colorAnim1: colors.purple,
    colorAnim2: colors.blue,
    citation:
      "test djjdjd ududh udud huh u duhduh dudu udhu dhud uh du hdudh ud uh duhd uhd u dhudh u hdud hu dh",
    profileImg: require("../../assets/projects/rozzos/profil.png"),
    mainTags: [{ text: "tag50.label", color: colors.purple }],
    subProjects: [
      {
        title: "proj6t1.label",
        list: ["proj61.label"],
        tags: [{ text: "tag50.label", color: colors.purple }],
        image: "",
        video: "",
      },
    ],
  },
  {
    mainLogo: require("../../assets/projects/mec/logo_png.png"),
    pathAnim1: require("../../assets/projects/mec/logo.png"),
    pathAnim2: require("../../assets/projects/mec/screen.png"),
    mainTitle: "proj7.label",
    name: "name2.label",
    colorAnim1: colors.purple,
    colorAnim2: colors.blue,
    citation:
      "test djjdjd ududh udud huh u duhduh dudu udhu dhud uh du hdudh ud uh duhd uhd u dhudh u hdud hu dh",
    profileImg: require("../../assets/projects/rozzos/profil.png"),
    mainTags: [
      { text: "tag30.label", color: colors.purple },
      { text: "tag35.label", color: colors.blue },
      { text: "tag51.label", color: colors.purple },
    ],
    subProjects: [
      {
        title: "proj7t1.label",
        list: ["proj71.label", "proj72.label", "proj73.label"],
        tags: [],
        image: require("../../assets/projects/mec/screen.png"),
        video: "",
      },
    ],
  },

  {
    mainLogo: require("../../assets/projects/upside_1/logo_png.png"),
    pathAnim1: require("../../assets/projects/upside_1/logo.png"),

    pathAnim2: require("../../assets/projects/upside_2/screen.jpeg"),
    mainTitle: "proj9.label",
    name: "name2.label",
    colorAnim1: colors.purple,
    colorAnim2: colors.blue,
    citation:
      "test djjdjd ududh udud huh u duhduh dudu udhu dhud uh du hdudh ud uh duhd uhd u dhudh u hdud hu dh",
    profileImg: require("../../assets/projects/rozzos/profil.png"),
    mainTags: [
      { text: "tag50.label", color: colors.purple },
      { text: "tag36.label", color: colors.blue },
      { text: "tag51.label", color: colors.blue },
    ],
    subProjects: [
      {
        title: "proj9t1.label",
        list: ["proj91.label", "proj92.label", "proj93.label", "proj94.label"],
        tags: [],
        image: require("../../assets/projects/upside_2/screen.jpeg"),
        video: "",
      },
    ],
  },
];

class BlackDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
      width: 0,
      height: 0,
      idSelected: -1,
    };
    this.contentRef = React.createRef();

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    const { t } = this.props;
    this.t = t;
  }

  updateDimensions() {
    this.setState({
      width: this.contentRef.current.getBoundingClientRect().width,
      height: this.contentRef.current.getBoundingClientRect().height,
    });
  }
  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }

  renderProjects() {
    let projects = [];
    if (this.state.windowWidth >= 600) {
      for (let i = 0; i < data.length; i += 3) {
        let intermediates = [];
        let max = Math.min(data.length - i, 3);
        for (let j = 0; j < max; j++) {
          intermediates.push(
            <div key={i + j + "intermediates"}>
              <ImageComponent
                onClick={() => this.deploy(i + j)}
                animation={this.state.idSelected === i + j}
                path={data[i + j].pathAnim1}
                path2={data[i + j].pathAnim2}
                fade1={data[j].colorAnim1}
                fade2={data[j].colorAnim2}
                width={
                  this.state.windowWidth < 600
                    ? this.state.width * 0.8
                    : this.state.width * 0.25
                }
                ratio="1.5"
                description={this.t(data[i + j].mainTitle)}
                alphaSubText="rgba(0, 0, 0, 0.5)"
                animationOnScroll={this.state.windowWidth < 600}
              ></ImageComponent>
            </div>
          );
        }
        projects.push(
          <div
            key={i + "data"}
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              {intermediates}
            </div>
            {this.renderViz(i)}
          </div>
        );
      }
      return (
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {projects}
        </div>
      );
    } else {
      for (let j = 0; j < data.length; j++) {
        projects.push(
          <div
            key={j + "data"}
            style={{
              display: "flex",
              width: "100%",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ImageComponent
              onClick={() => this.deploy(j)}
              animation={this.state.idSelected === j}
              path={data[j].pathAnim1}
              path2={data[j].pathAnim2}
              fade1={data[j].colorAnim1}
              fade2={data[j].colorAnim2}
              width={
                this.state.windowWidth < 600
                  ? this.state.width * 0.8
                  : this.state.width * 0.25
              }
              ratio="1.5"
              description={this.t(data[j].mainTitle)}
              alphaSubText="rgba(0, 0, 0, 0.5)"
              animationOnScroll={this.state.windowWidth < 600}
            ></ImageComponent>
            <ProjectDivComponent
              close={this.deploy.bind(this)}
              idSelection={j}
              data={data[j]}
              deploy={this.state.idSelected === j}
            ></ProjectDivComponent>
          </div>
        );
      }
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {projects}
        </div>
      );
    }
  }

  renderViz(indexMax) {
    let viz = [];
    if (this.state.windowWidth >= 600)
      for (let i = 0; i < data.length; i++) {
        if (i < indexMax + 3 && i >= indexMax)
          viz.push(
            <ProjectDivComponent
              key={i + "viz"}
              close={this.deploy.bind(this)}
              idSelection={i}
              data={data[i]}
              deploy={this.state.idSelected === i}
            ></ProjectDivComponent>
          );
      }
    return viz;
  }

  handleScroll(offset = 0) {
    if (this.state.windowWidth < 600) {
      if (!this.contentRef.current) return false;
      const top = this.contentRef.current.getBoundingClientRect().top;

      if (top + offset >= 0 && top - offset <= window.innerHeight) {
        this.setState({ animation: true });
      }
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();

    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", () => this.handleScroll());
  }

  componentDidUpdate() {
    if (window.outerWidth !== this.state.windowWidth) {
      this.setState({ windowWidth: window.outerWidth });
    }
    if (
      this.contentRef.current.getBoundingClientRect().width !==
        this.state.width ||
      this.contentRef.current.getBoundingClientRect().height !==
        this.state.height
    ) {
      this.setState({
        width: this.contentRef.current.getBoundingClientRect().width,
        height: this.contentRef.current.getBoundingClientRect().height,
      });
    }
  }

  deploy(idSelected) {
    if (this.state.idSelected === idSelected) {
      this.setState({ idSelected: -1 });
    } else this.setState({ idSelected: idSelected });
  }

  render() {
    return (
      <div
        ref={this.contentRef}
        style={{
          borderRadius: 10,
          display: "grid",
          gridTemplateColumns: "1fr",
        }}
        onMouseOver={() =>
          this.setState({
            animation: true,
          })
        }
        onMouseOut={() =>
          this.state.windowWidth >= 600 && this.setState({ animation: false })
        }
      >
        <div style={{ position: "relative" }}>
          <Spring
            top={this.state.animation ? -8 : 0}
            left={this.state.animation ? 8 : 0}
            backgroundColor={this.state.animation ? colors.blue : colors.purple}
          >
            {(styles) => (
              <animated.div
                style={{
                  ...styles,
                  height: this.state.height,
                  width: this.state.width,
                  position: "absolute",
                  borderRadius: 10,
                }}
              ></animated.div>
            )}
          </Spring>
        </div>
        <div
          style={{
            display: "flex",

            top: 0,
            left: 0,
            width:
              this.state.windowWidth < 600
                ? this.state.windowWidth * 0.94
                : this.state.windowWidth * 0.8,

            color: "white",
            borderRadius: 10,

            backgroundColor: colors.black,
            alignItems: "center",
            flexDirection: "column",
            padding: "8%",
            paddingLeft: "8%",
            paddingRight: "8%",
            boxSizing: "border-box",
            justifyContent: "space-between",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              display: "flex",

              width: "100%",
              marginBottom: 20,
              height: "100%",
              flexDirection: this.state.windowWidth < 600 ? "column" : "row",

              flex: 1,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <HighLightTextComponent
                text={this.t("title2.label")}
                highLightIdx={this.t("title2.label.indexHighlight")}
                textColor={colors.white}
                bgColor={colors.purple}
              ></HighLightTextComponent>
              <p
                style={{
                  fontFamily: "Goldman_medium",
                  fontSize: this.state.windowWidth < 600 ? "medium" : "large",
                  margin: 0,
                  marginTop: 10,
                  marginBottom: 10,
                  textJustify: "auto",
                  textAlign: "justify",
                }}
              >
                {this.t("presentation.label")}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={require("../../assets/oeil.webp")}
                alt="Animation oeil"
                style={{
                  objectFit: "contain",
                  width:
                    this.state.windowWidth < 600
                      ? this.state.width / 3
                      : this.state.width / 6,
                }}
              ></img>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              marginBottom: 20,
              flexDirection: this.state.windowWidth < 600 ? "column" : "row",
            }}
          >
            {this.state.windowWidth >= 600 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={require("../../assets/livre.webp")}
                  alt="Animation livre"
                  style={{
                    objectFit: "contain",
                    width:
                      this.state.windowWidth < 600
                        ? this.state.width / 3
                        : this.state.width / 6,
                  }}
                ></img>
              </div>
            )}

            <div
              style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                marginBottom: "auto",
                paddingTop: "5%",
              }}
            >
              <HighLightTextComponent
                text={this.t("title3.label")}
                highLightIdx={this.t("title3.label.indexHighlight")}
                textColor={colors.white}
                bgColor={colors.purple}
              ></HighLightTextComponent>
              <p
                style={{
                  fontFamily: "Goldman_medium",
                  fontSize: this.state.windowWidth < 600 ? "medium" : "large",
                  margin: 0,
                  marginTop: 10,
                  marginBottom: 10,
                  textAlign: "justify",
                }}
              >
                {this.t("presentation2.label")}
              </p>
            </div>
            {this.state.windowWidth < 600 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={require("../../assets/livre.webp")}
                  alt="Animation livre"
                  style={{
                    objectFit: "contain",
                    width:
                      this.state.windowWidth < 600
                        ? this.state.width / 3
                        : this.state.width / 6,
                  }}
                ></img>
              </div>
            )}
          </div>
          <div
            style={{
              flex: 1,
              width: "100%",
              flexDirection: "column",
              marginBottom: 20,
            }}
          >
            <HighLightTextComponent
              text={this.t("customers.label")}
              highLightIdx="1"
              textColor={colors.white}
              bgColor={colors.purple}
            ></HighLightTextComponent>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: this.state.windowWidth < 600 ? "column" : "row",
                alignItems: "center",
              }}
            >
              {this.renderProjects()}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              width: this.state.windowWidth < 600 ? "100%" : "50%",

              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h3
              style={{
                fontFamily: "Goldman_medium",
                fontSize: this.state.windowWidth < 600 ? "medium" : "large",
                textAlign: "center",
              }}
            >
              {this.t("call.label")}
            </h3>
            <ButtonComponent
              text={this.t("meeting1.label")}
              color={colors.yellow}
            ></ButtonComponent>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(BlackDivComponent);
