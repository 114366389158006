import React from 'react';
import { animated, Spring } from 'react-spring';
import './style.css';

class ImageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
    };
    this.imgRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      width: this.imgRef.current.offsetWidth,
      height: this.imgRef.current.offsetHeight,
    });
    window.addEventListener('scroll', () => this.handleScroll());
  }

  handleScroll(offset = 0) {
    if (this.props.animationOnScroll) {
      if (this.imgRef.current) {
        const topImageRef1 = this.imgRef.current.getBoundingClientRect().top;

        if (
          topImageRef1 + offset >= 0 &&
          topImageRef1 - offset <= (2 * window.innerHeight) / 4
        ) {
          this.setState({ animation: true });
        } else {
          this.setState({ animation: false });
        }
      }
    }
  }

  render() {
    return (
      <div
        onClick={() => this.props.onClick()}
        ref={this.imgRef}
        style={{
          cursor: 'pointer',

          position: 'relative',
          width: this.props.width,
          height: this.props.width / this.props.ratio,
          marginTop: 20,
        }}
        onMouseOver={() => this.setState({ animation: true })}
        onMouseOut={() => this.setState({ animation: false })}
      >
        <Spring
          bottom={this.state.animation || this.props.animation ? 6 : 0}
          left={this.state.animation || this.props.animation ? 6 : 0}
          backgroundColor={
            this.state.animation || this.props.animation
              ? this.props.fade1
              : this.props.fade2
          }
        >
          {(styles) => (
            <animated.div
              style={{
                ...styles,
                width: this.props.width,
                height: this.props.width / this.props.ratio,
                position: 'absolute',
                borderRadius: '10px',
              }}
            ></animated.div>
          )}
        </Spring>

        <img
          alt='Projet réalisé - avant'
          ref={this.imgRef}
          src={this.props.path}
          style={{
            position: 'absolute',
            backgroundColor: 'black',
            bottom: 0,
            left: 0,
            width: this.props.width,
            height: this.props.width / this.props.ratio,
            borderRadius: '10px',
          }}
        ></img>

        <Spring opacity={this.state.animation || this.props.animation ? 1 : 0}>
          {(styles) => (
            <div>
              <animated.img
                alt='Projet réalisé - après'
                src={this.props.path2}
                style={{
                  ...styles,
                  position: 'absolute',
                  backgroundColor: 'black',
                  bottom: 0,
                  left: 0,
                  width: this.props.width,
                  height: this.props.width / this.props.ratio,
                  borderRadius: '10px',
                }}
              ></animated.img>
              <div
                style={{
                  ...styles,
                  display: 'flex',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  backgroundColor: this.props.alphaSubText,
                  width: this.props.width,
                  height: this.props.width / this.props.ratio / 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                }}
              >
                <span style={{ margin: 10, fontFamily: 'Goldman_medium' }}>
                  {this.props.description}
                </span>
              </div>
            </div>
          )}
        </Spring>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <ImageComponent innerRef={ref} {...props} />
));
