import React from 'react';
import colors from '../../colors';
import HighLightTextComponent from '../highlightText/hightLightTextComponent';
import SkillsItemDivComponent from '../skillsItemDivComponent/SkillsItemDivComponent';
import { withTranslation } from 'react-i18next';
class SkillsDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
    };
    this.contentRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    const { t } = this.props;
    this.t = t;
  }

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          width: '100%',
          justifyContent: 'center',
          marginTop: 50,
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              padding: 'auto',
            }}
          >
            <HighLightTextComponent
              text={this.t('expertise.label')}
              highLightIdx='1'
              textColor={colors.black}
              bgColor={colors.blue}
            ></HighLightTextComponent>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            marginTop: 10,
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              padding: 'auto',
            }}
          >
            <HighLightTextComponent
              text={this.t('domain.label')}
              highLightIdx='0'
              fontSize='large'
              textColor={colors.black}
              bgColor={colors.white}
            ></HighLightTextComponent>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginBottom: 20,
            marginTop: 20,
            flexDirection: this.state.windowWidth < 600 ? 'column' : 'row',
          }}
        >
          {this.state.windowWidth < 600 ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <SkillsItemDivComponent
                  color={colors.blue}
                  title={this.t('vision.label')}
                  list={[
                    this.t('vision1.label'),
                    this.t('vision2.label'),
                    this.t('vision3.label'),
                    this.t('vision4.label'),
                  ]}
                ></SkillsItemDivComponent>
                <SkillsItemDivComponent
                  color={colors.yellow}
                  title={this.t('nlp.label')}
                  list={[
                    this.t('nlp1.label'),
                    this.t('nlp2.label'),
                    this.t('nlp3.label'),
                  ]}
                ></SkillsItemDivComponent>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <SkillsItemDivComponent
                  color={colors.purple}
                  title={this.t('analysis.label')}
                  list={[
                    this.t('analysis1.label'),
                    this.t('analysis2.label'),
                    this.t('analysis3.label'),
                  ]}
                ></SkillsItemDivComponent>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <SkillsItemDivComponent
                color={colors.yellow}
                title={this.t('vision.label')}
                list={[
                  this.t('vision1.label'),
                  this.t('vision2.label'),
                  this.t('vision3.label'),
                  this.t('vision4.label'),
                ]}
              ></SkillsItemDivComponent>
              <SkillsItemDivComponent
                color={colors.blue}
                title={this.t('nlp.label')}
                list={[
                  this.t('nlp1.label'),
                  this.t('nlp2.label'),
                  this.t('nlp3.label'),
                ]}
              ></SkillsItemDivComponent>{' '}
              <SkillsItemDivComponent
                color={colors.purple}
                title={this.t('analysis.label')}
                list={[
                  this.t('analysis1.label'),
                  this.t('analysis2.label'),
                  this.t('analysis3.label'),
                ]}
              ></SkillsItemDivComponent>
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              padding: 'auto',
            }}
          >
            <HighLightTextComponent
              text={this.t('supports.label')}
              highLightIdx='0'
              fontSize='large'
              textColor={colors.black}
              bgColor={colors.white}
            ></HighLightTextComponent>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            marginBottom: 20,
            marginTop: 20,
            flexDirection: this.state.windowWidth < 600 ? 'column' : 'row',
          }}
        >
          {this.state.windowWidth < 600 ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <SkillsItemDivComponent
                  color={colors.purple}
                  image={true}
                  title={this.t('supports1.label')}
                  file={require('../../assets/icons/mobile-app.png')}
                ></SkillsItemDivComponent>
                <SkillsItemDivComponent
                  color={colors.blue}
                  image={true}
                  title={this.t('supports2.label')}
                  file={require('../../assets/icons/web-programming.png')}
                ></SkillsItemDivComponent>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <SkillsItemDivComponent
                  color={colors.yellow}
                  image={true}
                  title={this.t('supports3.label')}
                  file={require('../../assets/icons/api.png')}
                ></SkillsItemDivComponent>
                <SkillsItemDivComponent
                  color={colors.blue}
                  image={true}
                  title={this.t('supports4.label')}
                  file={require('../../assets/icons/cloud.png')}
                ></SkillsItemDivComponent>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <SkillsItemDivComponent
                color={colors.blue}
                image={true}
                title={this.t('supports1.label')}
                file={require('../../assets/icons/mobile-app.png')}
              ></SkillsItemDivComponent>
              <SkillsItemDivComponent
                color={colors.purple}
                image={true}
                title={this.t('supports2.label')}
                file={require('../../assets/icons/web-programming.png')}
              ></SkillsItemDivComponent>
              <SkillsItemDivComponent
                color={colors.yellow}
                image={true}
                title={this.t('supports3.label')}
                file={require('../../assets/icons/api.png')}
              ></SkillsItemDivComponent>
              <SkillsItemDivComponent
                color={colors.blue}
                image={true}
                title={this.t('supports4.label')}
                file={require('../../assets/icons/cloud.png')}
              ></SkillsItemDivComponent>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(SkillsDivComponent);
