import React from 'react';
import { animated, Spring } from 'react-spring';
import colors from '../../colors';
import HighLightTextComponent from '../highlightText/hightLightTextComponent';

class SkillsItemDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
      width: 0,
      height: 0,
    };
    this.contentRef = React.createRef();

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  updateDimensions() {
    this.setState({
      width: this.contentRef.current.getBoundingClientRect().width,
      height: this.contentRef.current.getBoundingClientRect().height,
    });
  }
  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }

  handleScroll(offset = 0) {
    if (this.state.windowWidth < 600) {
      if (!this.contentRef.current) return false;
      const top = this.contentRef.current.getBoundingClientRect().top;

      if (top + offset >= 0 && top - offset <= window.innerHeight) {
        this.setState({ animation: true });
      }
      const topImageRef1 = this.contentRef.current.getBoundingClientRect().top;

      if (
        topImageRef1 + offset >= 0 &&
        topImageRef1 - offset <= (2 * window.innerHeight) / 4
      ) {
        this.setState({ animation: true });
      } else {
        this.setState({ animation: false });
      }
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();

    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', () => this.handleScroll());
  }

  componentDidUpdate() {
    if (window.outerWidth !== this.state.windowWidth) {
      this.setState({ windowWidth: window.outerWidth });
    }
    if (
      this.contentRef.current.getBoundingClientRect().width !==
        this.state.width ||
      this.contentRef.current.getBoundingClientRect().height !==
        this.state.height
    ) {
      this.setState({
        width: this.contentRef.current.getBoundingClientRect().width,
        height: this.contentRef.current.getBoundingClientRect().height,
      });
    }
  }
  renderList() {
    let ps = [];
    for (let i = 0; i < this.props.list.length; i++) {
      ps.push(
        <div key={this.props.list[i]}>
          <p
            style={{
              padding: 0,
              margin: 0,
              marginTop: 5,
              fontFamily: 'Goldman_medium',
              fontSize: this.state.windowWidth < 600 ? 'small' : 'small',
            }}
          >
            {this.props.list[i]}
          </p>
          <hr
            style={{
              borderWidth: 0.5,
              borderColor: this.props.color,
              marginTop: 4,
              marginBottom: 4,
            }}
          />
        </div>
      );
    }
    return ps;
  }
  render() {
    return (
      <div
        ref={this.contentRef}
        style={{
          marginBottom: 20,
          marginRight: this.state.windowWidth < 600 ? 0 : 20,
          borderRadius: 10,
          display: 'grid',
          gridTemplateColumns: '1fr',
        }}
        onMouseOver={() =>
          this.setState({
            animation: true,
          })
        }
        onMouseOut={() =>
          this.state.windowWidth >= 600 && this.setState({ animation: false })
        }
      >
        <div style={{ position: 'relative' }}>
          <Spring
            top={this.state.animation ? -6 : 0}
            left={this.state.animation ? 6 : 0}
            backgroundColor={
              this.state.animation ? this.props.color : colors.purple
            }
          >
            {(styles) => (
              <animated.div
                style={{
                  ...styles,
                  width:
                    this.state.windowWidth < 600
                      ? (this.state.windowWidth * 0.94) / 2 - 10
                      : this.state.windowWidth * 0.18,
                  height:
                    this.state.windowWidth < 600
                      ? (this.state.windowWidth * 1.4) / 2 - 10
                      : this.state.windowHeight * 0.35,
                  position: 'absolute',
                  borderRadius: 10,
                }}
              ></animated.div>
            )}
          </Spring>
        </div>
        <div
          // className='gradient-background'
          style={{
            display: 'flex',
            top: 0,
            left: 0,
            width:
              this.state.windowWidth < 600
                ? (this.state.windowWidth * 0.94) / 2 - 10
                : this.state.windowWidth * 0.18,
            height:
              this.state.windowWidth < 600
                ? (this.state.windowWidth * 1.4) / 2 - 10
                : this.state.windowHeight * 0.35,

            color: 'white',
            borderRadius: 10,
            backgroundColor: colors.black,

            alignItems: 'center',
            flexDirection: 'column',
            // padding: '8%',
            // paddingLeft: '8%',
            // paddingRight: '8%',
            padding: '10%',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            zIndex: 1000,
          }}
        >
          {this.props.image ? (
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'space-around',
                flexDirection: 'column',
              }}
            >
              <img
                style={{ display: 'flex', height: '35%', aspectRatio: 1 }}
                src={this.props.file}
              ></img>
              <p
                style={{
                  fontSize: 'small',
                  textAlign: 'center',
                  fontFamily: 'Goldman_bold',
                }}
              >
                {this.props.title}
              </p>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginBottom: 20,
                height: '100%',
                flexDirection: 'column',

                flex: 1,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '20%',
                }}
              >
                <HighLightTextComponent
                  text={this.props.title}
                  highLightIdx='0'
                  fontSize='large'
                  textColor={colors.white}
                  bgColor={colors.black}
                ></HighLightTextComponent>
              </div>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                {this.renderList()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SkillsItemDivComponent;
