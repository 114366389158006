import React from 'react';
import { animated, Spring } from 'react-spring';

class HighLightTextComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
    };
    this.textRef = React.createRef();
    this.elementRef = React.createRef();
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  isInViewport(offset = 0) {
    if (!this.elementRef.current) return false;
    const top = this.elementRef.current.getBoundingClientRect().top;
    if (top + offset >= 0 && top - offset <= window.innerHeight) {
      this.setState({ animation: true });
    } else {
      this.setState({ animation: false });
    }
  }

  updateDimensions() {
    this.setState({
      width: this.textRef.current.offsetWidth + 5,
      height: this.textRef.current.offsetHeight,
      left: this.elementRef.current.offsetLeft,
      top: this.elementRef.current.offsetTop,
      hightLightWidth: this.elementRef.current.offsetWidth,
      hightLightHeight: this.elementRef.current.offsetHeight,
    });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);

    window.addEventListener('scroll', () => this.isInViewport());
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.isInViewport());
  }

  componentDidUpdate() {
    if (
      this.state.left !== this.elementRef.current.offsetLeft ||
      this.state.top !== this.elementRef.current.offsetTop
    ) {
      this.setState({
        width: this.textRef.current.offsetWidth + 5,
        height: this.textRef.current.offsetHeight,
        left: this.elementRef.current.offsetLeft,
        top: this.elementRef.current.offsetTop,
        hightLightWidth: this.elementRef.current.offsetWidth,
        hightLightHeight: this.elementRef.current.offsetHeight,
      });
    }
  }

  renderWords() {
    const words = this.props.text.split(' ');
    let elements = [];
    let i = 0;
    words.forEach((element) => {
      if (this.props.highLightIdx === i.toString()) {
        elements.push(
          <span key={element} ref={this.elementRef}>
            {element + ' '}
          </span>
        );
      } else {
        elements.push(<span key={element}>{element + ' '}</span>);
      }
      i = i + 1;
    });
    this.isWordsRendered = true;
    return elements;
  }

  render() {
    return (
      <div
        ref={this.textRef}
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
        }}
      >
        <Spring width={this.state.animation ? this.state.hightLightWidth : 0}>
          {(styles) => (
            <animated.div
              style={{
                ...styles,
                height: this.state.hightLightHeight,
                position: 'absolute',
                left: this.state.left,
                top: this.state.top,
                borderRadius: '10px',
                backgroundColor: this.props.bgColor,
              }}
            ></animated.div>
          )}
        </Spring>
        <div
          style={{
            fontSize: this.props.fontSize ? this.props.fontSize : 'xx-large',
            color: this.props.textColor,
            bottom: 0,
            left: 0,
            borderRadius: 10,
            zIndex: 1000,
          }}
        >
          {this.renderWords()}
        </div>
      </div>
    );
  }
}

export default HighLightTextComponent;
