import React from 'react';
import colors from '../../colors';
import HighLightTextComponent from '../highlightText/hightLightTextComponent';
import TranslateDivComponent from '../translateDiv/translateDivComponent';
import { withTranslation } from 'react-i18next';
class WhiteDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
    };
    this.contentRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    const { t } = this.props;
    this.t = t;
  }

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          marginTop: 50,
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              padding: 'auto',
            }}
          >
            <HighLightTextComponent
              text={this.t('title4.label')}
              highLightIdx='2'
              textColor={colors.black}
              bgColor={colors.blue}
            ></HighLightTextComponent>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            height: '100%',
            marginBottom: 20,
            marginTop: 20,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {this.state.windowWidth >= 600 && (
            <div
              style={{
                display: 'flex',
                paddingBottom: 20,
                width: this.state.windowWidth * 0.8 - 400,
                height: 20,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: colors.yellow,
                    padding: 2,
                    borderRadius: 10,
                  }}
                >
                  <span style={{ fontFamily: 'Goldman_bold' }}>1.</span>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: colors.yellow,
                    padding: 2,
                    borderRadius: 10,
                  }}
                >
                  <span style={{ fontFamily: 'Goldman_bold' }}>2.</span>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: colors.yellow,
                    padding: 2,
                    borderRadius: 10,
                  }}
                >
                  <span style={{ fontFamily: 'Goldman_bold' }}>3.</span>
                </div>
              </div>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              marginBottom: 30,
            }}
          >
            <TranslateDivComponent
              gradient={'gradient-background-2'}
              mobile={this.state.windowWidth < 600}
              colorFadeIn={colors.yellow}
              colorFadeOut={colors.blue}
              icon3={require('../../assets/icons/1-3.png')}
              totalWidth={
                this.state.windowWidth < 600
                  ? this.state.windowWidth * 0.94
                  : this.state.windowWidth * 0.8
              }
              textColor={colors.black}
              elements={[
                {
                  text: this.t('strategy1.label'),
                  icon: require('../../assets/icons/1-1.png'),
                },
                {
                  text: this.t('strategy2.label'),
                  icon: require('../../assets/icons/1-2.png'),
                },
                {
                  text: this.t('strategy3.label'),
                  icon: require('../../assets/icons/1-3.png'),
                },
              ]}
              width={this.state.windowWidth < 600 ? 100 : 200}
              height={this.state.windowWidth < 600 ? 600 : 170}
              inverse={false}
              title={this.t('strategy.label')}
            ></TranslateDivComponent>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: 30,
            }}
          >
            <TranslateDivComponent
              gradient={'gradient-background-2'}
              mobile={this.state.windowWidth < 600}
              colorFadeIn={colors.yellow}
              colorFadeOut={colors.purple}
              totalWidth={
                this.state.windowWidth < 600
                  ? this.state.windowWidth * 0.94
                  : this.state.windowWidth * 0.8
              }
              textColor={colors.white}
              elements={[
                {
                  text: this.t('data1.label'),
                  icon: require('../../assets/icons/2-1.png'),
                },

                {
                  text: this.t('data2.label'),
                  icon: require('../../assets/icons/2-2.png'),
                },
                {
                  text: this.t('data3.label'),
                  icon: require('../../assets/icons/2-3.png'),
                },
              ]}
              width={this.state.windowWidth < 600 ? 100 : 200}
              height={this.state.windowWidth < 600 ? 600 : 170}
              inverse={true}
              title={this.t('data.label')}
            ></TranslateDivComponent>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: 30,
            }}
          >
            <TranslateDivComponent
              gradient={'gradient-background-2'}
              mobile={this.state.windowWidth < 600}
              colorFadeIn={colors.yellow}
              colorFadeOut={colors.blue}
              textColor={colors.black}
              totalWidth={
                this.state.windowWidth < 600
                  ? this.state.windowWidth * 0.94
                  : this.state.windowWidth * 0.8
              }
              elements={[
                {
                  text: this.t('dev1.label'),
                  icon: require('../../assets/icons/3-1.png'),
                },
                {
                  text: this.t('dev2.label'),
                  icon: require('../../assets/icons/3-2.png'),
                },
                {
                  text: this.t('dev3.label'),
                  icon: require('../../assets/icons/3-3.png'),
                },
              ]}
              width={this.state.windowWidth < 600 ? 100 : 200}
              height={this.state.windowWidth < 600 ? 600 : 170}
              inverse={false}
              title={this.t('dev.label')}
            ></TranslateDivComponent>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <TranslateDivComponent
              gradient={'gradient-background-2'}
              mobile={this.state.windowWidth < 600}
              colorFadeIn={colors.yellow}
              colorFadeOut={colors.purple}
              totalWidth={
                this.state.windowWidth < 600
                  ? this.state.windowWidth * 0.94
                  : this.state.windowWidth * 0.8
              }
              textColor={colors.white}
              elements={[
                {
                  text: this.t('deployment1.label'),
                  icon: require('../../assets/icons/4-1.png'),
                },
                {
                  text: this.t('deployment2.label'),
                  icon: require('../../assets/icons/4-2.png'),
                },
                {
                  text: this.t('deployment3.label'),
                  icon: require('../../assets/icons/4-3.png'),
                },
              ]}
              width={this.state.windowWidth < 600 ? 100 : 200}
              height={this.state.windowWidth < 600 ? 600 : 170}
              inverse={true}
              title={this.t('deployment.label')}
            ></TranslateDivComponent>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(WhiteDivComponent);
