import React from 'react';
import { animated, Spring } from 'react-spring';
import colors from '../../colors';
import HighLightTextComponent2 from '../hilighTextComponent2/hightLightTextComponent';
import { withTranslation } from 'react-i18next';

class TitleDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
      colors: [colors.yellow, colors.blue, colors.purple],
      opacity: true,
    };
    this.contentRef = React.createRef();
    this.videoRef = React.createRef();

    this.changeColor = this.changeColor.bind(this);
    this.resetVideo = this.resetVideo.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.interval = null;
  }
  arrayRotate(arr, reverse) {
    if (reverse) arr.unshift(arr.pop());
    else arr.push(arr.shift());
    return arr;
  }
  handleScroll(offset = 0) {
    if (this.state.windowWidth < 600) {
      if (!this.contentRef.current) return false;
      const top = this.contentRef.current.getBoundingClientRect().top;

      if (top + offset >= 0 && top - offset <= window.innerHeight) {
        this.setState({ animation: true });
      }
    }
  }
  resetVideo() {
    this.videoRef.current.currentTime = 20;
    this.videoRef.current.play();
  }

  changeColor() {
    let colors = this.state.colors;
    let opacity = this.state.opacity;

    colors = this.arrayRotate(colors, false);
    this.setState({
      colors: colors,
      opacity: !opacity,
    });
  }
  updateDimensions() {
    this.setState({
      width: this.contentRef.current.getBoundingClientRect().width,
      height: this.contentRef.current.getBoundingClientRect().height,
    });
  }
  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.interval = setInterval(async () => {
      this.changeColor();
    }, 2000);
    this.updateWindowDimensions();

    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('beforeunload', () =>
      this.resetInterval(this.interval)
    );
    window.addEventListener('scroll', () => this.handleScroll());
  }

  componentDidUpdate() {
    if (window.outerWidth !== this.state.windowWidth) {
      this.setState({ windowWidth: window.outerWidth });
    }
    if (
      this.contentRef.current.getBoundingClientRect().width !==
        this.state.width ||
      this.contentRef.current.getBoundingClientRect().height !==
        this.state.height
    ) {
      this.setState({
        width: this.contentRef.current.getBoundingClientRect().width,
        height: this.contentRef.current.getBoundingClientRect().height,
      });
    }
  }

  resetInterval(interval) {
    clearInterval(interval);
  }

  componentWillUnmount() {
    if (this.interval) {
      this.resetInterval(this.interval);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div
        ref={this.contentRef}
        onClick={() => {}}
        style={{
          borderRadius: 10,
          display: 'grid',
          gridTemplateColumns: '1fr',
        }}
        onMouseOver={() =>
          this.setState({
            width: this.contentRef.current.offsetWidth,
            height: this.contentRef.current.offsetHeight,
            animation: true,
          })
        }
        onMouseOut={() =>
          this.state.windowWidth >= 600 && this.setState({ animation: false })
        }
      >
        <div style={{ position: 'relative' }}>
          <Spring
            top={this.state.animation ? -8 : 0}
            left={this.state.animation ? 8 : 0}
            backgroundColor={this.state.animation ? '#ffe82c' : '#b599db'}
          >
            {(styles) => (
              <animated.div
                style={{
                  ...styles,
                  height: this.state.height,
                  width: this.state.width,
                  position: 'absolute',
                  borderRadius: 10,
                }}
              ></animated.div>
            )}
          </Spring>
        </div>

        <div
          style={{
            display: 'flex',

            height: '80vh',
            top: 0,
            left: 0,
            width:
              this.state.windowWidth < 600
                ? this.state.windowWidth * 0.94
                : this.state.windowWidth * 0.8,
            backgroundColor: colors.black,
            color: 'white',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '5%',
            paddingLeft: '8%',
            paddingRight: '8%',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            overflow: 'hidden',
            borderRadius: 10,
            transform: 'translateZ(0)',
          }}
        >
          <div style={{ borderRadius: 10, overflow: 'hidden' }}>
            <img
              alt='Animation accueil avec logo'
              src={require('../../assets/title55.webp')}
              style={{
                borderRadius: 'inherit',

                opacity: 1,
                position: 'absolute',
                transform: 'translate(-50%,-50%)',
                height: this.state.height / 1.2,
                top:
                  this.state.windowWidth < 600
                    ? this.state.height / 8
                    : this.state.height / 2,
                left:
                  this.state.windowWidth < 600
                    ? this.state.width / 2
                    : this.state.width / 3,
              }}
            ></img>
            {/* <video
              ref={this.videoRef}
              onEnded={() => this.resetVideo()}
              style={{
                borderRadius: 'inherit',

                opacity: 1,
                position: 'absolute',
                transform: 'translate(-50%,-50%)',
                height: this.state.height / 1.5,
                top:
                  this.state.windowWidth < 600
                    ? this.state.height / 8
                    : this.state.height / 2,
                left:
                  this.state.windowWidth < 600
                    ? this.state.width / 2
                    : this.state.width / 3,
              }}
              playsInline
              autoPlay
              muted
            >
              <source
                src={require('../../assets/yo2.m4v')}
                type='video/mp4'
              ></source>
            </video> */}
          </div>
          <Spring
            config={{ duration: 2000 }}
            opacity={this.state.opacity ? 0.2 : 0.6}
          >
            {(styles) => (
              <animated.span
                style={{
                  ...styles,
                  fontFamily: 'Goldman_bold',
                  position: 'absolute',
                  bottom: 38,
                }}
              >
                {t('subtitleScroll.label')}
              </animated.span>
            )}
          </Spring>
          <svg
            style={{ position: 'absolute', bottom: 15 }}
            width='18'
            viewBox='0 0 24 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <Spring color={this.state.colors[0]} config={{ duration: 1000 }}>
              {(styles) => (
                <animated.path
                  d='M10.9393 25.0607C11.5251 25.6464 12.4749 25.6464 13.0607 25.0607L22.6066 15.5147C23.1924 14.9289 23.1924 13.9792 22.6066 13.3934C22.0208 12.8076 21.0711 12.8076 20.4853 13.3934L12 21.8787L3.51473 13.3934C2.92894 12.8076 1.97919 12.8076 1.39341 13.3934C0.807619 13.9792 0.807618 14.9289 1.3934 15.5147L10.9393 25.0607ZM10.5 22L10.5 24L13.5 24L13.5 22L10.5 22Z'
                  fill={styles.color}
                />
              )}
            </Spring>
            <Spring color={this.state.colors[1]} config={{ duration: 1000 }}>
              {(styles) => (
                <animated.path
                  d='M10.9393 19.0607C11.5251 19.6464 12.4749 19.6464 13.0607 19.0607L22.6066 9.51473C23.1924 8.92894 23.1924 7.97919 22.6066 7.39341C22.0208 6.80762 21.0711 6.80762 20.4853 7.3934L12 15.8787L3.51473 7.39339C2.92894 6.8076 1.97919 6.8076 1.39341 7.39339C0.807619 7.97918 0.807618 8.92892 1.3934 9.51471L10.9393 19.0607ZM10.5 16L10.5 18L13.5 18L13.5 16L10.5 16Z'
                  fill={styles.color}
                />
              )}
            </Spring>
            <Spring color={this.state.colors[2]} config={{ duration: 1000 }}>
              {(styles) => (
                <animated.path
                  d='M10.9393 13.0607C11.5251 13.6464 12.4749 13.6464 13.0607 13.0607L22.6066 3.51473C23.1924 2.92894 23.1924 1.97919 22.6066 1.39341C22.0208 0.807619 21.0711 0.807618 20.4853 1.3934L12 9.87868L3.51473 1.39339C2.92894 0.807605 1.97919 0.807604 1.39341 1.39339C0.807619 1.97918 0.807618 2.92892 1.3934 3.51471L10.9393 13.0607ZM10.5 10L10.5 12L13.5 12L13.5 10L10.5 10Z'
                  fill={styles.color}
                />
              )}
            </Spring>
          </svg>

          <div
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 20,
              flex: 1,
              justifyContent: 'space-around',
            }}
          >
            {this.state.windowWidth >= 600 && (
              <div
                style={{
                  display: 'flex',
                  width: '40%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              ></div>
            )}

            <div
              style={{
                display: 'flex',
                width: this.state.windowWidth < 600 ? '100%' : '50%',

                height: '100%',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <HighLightTextComponent2
                text={'maintitle.label'}
                highLightIdx='0'
                textColor={colors.white}
                bgColor={colors.purple}
              ></HighLightTextComponent2>
              <h3
                style={{
                  fontFamily: 'Goldman_medium',
                  fontSize: this.state.windowWidth < 600 ? 'medium' : 'large',
                  zIndex: 300,
                }}
              >
                {t('subtitle.label')}
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(TitleDivComponent);
