import './style.css';
import React from 'react';
import { Spring, animated } from 'react-spring';
import colors from '../../colors';
import { Link } from 'react-router-dom';

class NavBarButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      over: false,
    };
    this.bg = React.createRef();
    this.resizeHandler = this.resizeHandler.bind(this);
  }

  resizeHandler() {
    this.setState({
      width: this.bg.current.offsetWidth,
      height: this.bg.current.offsetHeight,
    });
  }

  componentDidMount() {
    setTimeout(this.resizeHandler, 200);
  }

  setOver() {
    this.setState({
      over: true,
      width: this.bg.current.offsetWidth,
      height: this.bg.current.offsetHeight,
    });
  }

  setOut() {
    this.setState({ over: false });
  }

  render() {
    return (
      <div>
        <div
          onMouseOver={() => this.setOver()}
          onMouseOut={() => this.setOut()}
          style={{
            width: this.state.width,
            height: this.state.height,
            marginRight: 10,
          }}
          className='buttonContainer'
        >
          {this.props.activated && (
            <Spring width={this.state.width}>
              {(styles) => (
                <animated.div
                  style={{ ...styles, height: this.state.height }}
                  className='buttonActivated'
                ></animated.div>
              )}
            </Spring>
          )}
          {!this.props.activated && (
            <Spring
              bottom={this.state.over ? 8 : 0}
              left={this.state.over ? 8 : 0}
              backgroundColor={this.state.over ? colors.blue : colors.white}
            >
              {(styles) => (
                <animated.div
                  style={{
                    ...styles,
                    width: this.state.width,
                    height: this.state.height,
                  }}
                  className='buttonActivated'
                ></animated.div>
              )}
            </Spring>
          )}

          <Link
            onClick={() =>
              this.props.changeButtonActivated(this.props.idButton)
            }
            to={this.props.link}
            ref={this.bg}
            className='text'
            style={{ fontFamily: 'Goldman_bold', textDecoration: 'none' }}
          >
            {this.props.text}
          </Link>
        </div>
      </div>
    );
  }
}

export default NavBarButton;
