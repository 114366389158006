import React from "react";
import { animated, Spring } from "react-spring";
import HighLightTextComponent from "../highlightText/hightLightTextComponent";
import { withTranslation } from "react-i18next";
import { ChatGPTAPI } from "chatgpt";
import colors from "../../colors";
import ButtonComponent from "../button/buttonComponent";
import BeatLoader from "react-spinners/BeatLoader";

export function customFetch(...args) {
  return fetch.apply(window, args);
}

const radiant = `{
"entreprise": "Radiant",
"description": "Radiant est une entreprise spécialisée dans le développement de solutions d'intelligence artificielle pour les petites et moyennes entreprises.",
"services": [
  {
    "nom": "Recherche scientifique et étude de faisabilité",
    "description": "Nous évaluons les possibilités d'implémentation de l'IA dans votre entreprise, en tenant compte de vos objectifs et contraintes."
  },
  {
    "nom": "Gestion des données",
    "description": "Nous nous occupons de tous les aspects liés aux données, tels que la collecte, l'annotation et la préparation pour l'entraînement des modèles."
  },
  {
    "nom": "Gestion des besoins matériels",
    "description": "Nous prenons en charge les besoins en puissance de calcul, en infrastructure et en matériel pour le développement de vos solutions d'IA."
  },
  {
    "nom": "Développement, entrainement, tests et mise en production",
    "description": "Nous développons des modèles d'IA personnalisés, les entraînons, les testons et les mettons en production pour répondre à vos besoins spécifiques."
  }
],
"objectif_chatbot": "Le but de ce chatbot est d'aider les visiteurs du site à comprendre comment les prestations de Radiant peut apporter des avantages à leur entreprise en fonction de leur domaine ou de leurs besoins spécifiques. Le chatbot doit repondre des exemples concret utilisant l'IA qui peuvent ameliorer les performances de lentreprises du visiteur ou ses produits sous forme de liste ordonnée pratique, comprehensible pour un novice. Ne repond que par cette liste. TRES IMPORTANT : Chaque element de la liste doit etre de la forme titre: description"
}`;

const test = [
  "Analyse des commentaires clients : L'IA peut être utilisée pour analyser les commentaires laissés par vos clients sur les réseaux sociaux ou sur d'autres plateformes afin de déterminer les points forts et les points faibles de votre restaurant.",
  "Optimisation des menus : L'IA peut aider à optimiser votre menu en fonction des préférences des clients, en identifiant les plats les plus populaires et en suggérant des modifications pour augmenter les ventes.",
  "Prédiction de la demande : L'IA peut être utilisée pour prédire la demande de vos produits, vous permettant de mieux planifier vos stocks et éviter les pertes.",
  "Gestion des réservations : L'IA peut faciliter la gestion des réservations en proposant des horaires adaptés aux préférences des clients et en prévoyant les annulations éventuelles.",
  "Assistance à la prise de décision : L'IA peut fournir des analyses et des conseils pour aider à prendre des décisions stratégiques telles que l'ouverture de nouveaux sites ou l'ajout de nouveaux plats au menu.",
];

class StatsDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: [
        { top: 0, opacity: 0 },
        { top: 20, opacity: 0.5 },
        { top: 40, opacity: 1 },
        { top: 60, opacity: 1 },
        { top: 80, opacity: 1 },
        { top: 100, opacity: 0.5 },
        { top: 120, opacity: 0 },
      ],
      words: [0, 1, 2, 3, 4, 5, 6],
      value: "",
      response: "",
    };

    this.interval = null;
    this.id = 0;
    this.words = [
      { name: "p1.label", percent: 84 },
      { name: "p2.label", percent: 74 },
      { name: "p3.label", percent: 71 },
      { name: "p4.label", percent: 59 },
      { name: "p5.label", percent: 55 },
      { name: "p6.label", percent: 53 },
      { name: "p7.label", percent: 46 },
      { name: "p8.label", percent: 10 },
      { name: "p9.label", percent: 39 },
      { name: "p10.label", percent: 36 },
      { name: "p11.label", percent: 31 },
      { name: "p12.label", percent: 27 },
      { name: "p13.label", percent: 26 },
      { name: "p14.label", percent: 24 },
      { name: "p15.label", percent: 17 },
      { name: "p16.label", percent: 9 },
    ];
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    const { t } = this.props;
    this.t = t;
  }

  async delay(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }

  getIndexPercent() {
    for (let index = 0; index < this.state.pos.length; index++) {
      if (this.state.pos[index].top === 60) {
        return index;
      }
    }
  }

  async sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  async askGPT() {
    if (this.state.loading) return;
    this.setState({ loading: true });
    let colorsArray = [
      colors.yellow,
      colors.purple,
      colors.blue,
      colors.yellow,
      colors.purple,
      colors.blue,

      colors.yellow,
      colors.purple,
      colors.blue,
    ];
    colorsArray.sort(() => Math.random() - 0.5);

    const api = new ChatGPTAPI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      fetch: customFetch,
    });

    //await this.sleep(10000);
    this.setState({ colors: colorsArray, error: false });
    const res = await api.sendMessage(
      this.t("gptcontext.label") + this.state.value,
      {
        // print the partial response as the AI is "typing"
        onProgress: (partialResponse) => {
          const regex = /^\d+\.\s+/; // Matches lines starting with a number followed by a dot and a space
          const outputArray = partialResponse.text
            .split("\n")
            .filter((line) => regex.test(line));

          this.setState({ response: outputArray });
          window.dispatchEvent(new Event("scroll"));
        },
      }
    );
    const regex = /^\d+\.\s+/; // Matches lines starting with a number followed by a dot and a space
    const outputArray = res.text.split("\n").filter((line) => regex.test(line));

    if (outputArray.length > 0) {
      this.setState({ loading: false, success: true });
    } else {
      this.setState({ loading: false, error: true });
    }
    window.dispatchEvent(new Event("scroll"));
  }

  arrayRotate(arr, reverse) {
    if (reverse) arr.unshift(arr.pop());
    else arr.push(arr.shift());
    return arr;
  }

  test() {
    let array = this.state.pos;
    array = this.arrayRotate(array, true);
    let wordsIdx = this.state.words;
    if (wordsIdx[this.id] + 7 > this.words.length - 1) {
      let new_id = wordsIdx[this.id] + 7 - this.words.length;
      wordsIdx[this.id] = new_id;
    } else {
      wordsIdx[this.id] = wordsIdx[this.id] + 7;
    }
    if (this.id === 6) {
      this.id = 0;
    } else {
      this.id += 1;
    }
    this.setState({ pos: array, words: wordsIdx });
  }
  componentDidMount() {
    if (this.interval === null) {
      this.interval = setInterval(this.test.bind(this), 2000);
    }
    this.updateWindowDimensions();

    window.addEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }
  resetInterval(interval) {
    clearInterval(interval);
  }

  componentWillUnmount() {
    this.resetInterval(this.interval);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  renderResponse() {
    let elements = [];

    for (let i = 0; i < this.state.response.length; i++) {
      let title = this.state.response[i].split(":")[0].split(".")[1];
      let value = this.state.response[i].split(":")[1];
      elements.push(
        <div
          style={{
            margin: 5,
            backgroundColor: colors.black,
            borderRadius: 10,
          }}
        >
          <p style={{ color: this.state.colors[i], fontSize: 16 }}>{title}</p>
          <p style={{ color: colors.white, fontSize: 14 }}>{value}</p>
        </div>
      );
    }
    return (
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          display: "flex",
          flexDirection: this.state.windowWidth < 600 ? "column" : "row",
        }}
      >
        {elements}
      </div>
    );
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: this.state.windowWidth < 600 ? "column" : "row",
            marginBottom: "5%",
          }}
        >
          {this.state.windowWidth < 600 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: this.state.windowWidth < 600 ? "100%" : "50%",
              }}
            >
              <HighLightTextComponent
                margin={20}
                text={this.t("title1.label")}
                highLightIdx={this.t("title1.label.indexHighlight")}
                textColor={colors.black}
                bgColor={colors.yellow}
              ></HighLightTextComponent>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: this.state.windowWidth < 600 ? "100%" : "50%",
            }}
          >
            <h4 style={{ opacity: 0.6 }}>{this.t("growth.label")}</h4>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  width:
                    this.state.windowWidth < 600
                      ? this.state.windowWidth * 0.94
                      : 250,
                  height: 150,
                }}
              >
                <Spring
                  backgroundColor={
                    this.state.pos[0].top === 60 ? colors.yellow : colors.white
                  }
                  opacity={this.state.pos[0].opacity}
                  top={this.state.pos[0].top}
                >
                  {(styles) => (
                    <animated.div
                      style={{
                        ...styles,
                        position: "absolute",
                        borderRadius: 10,
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {this.t(this.words[this.state.words[0]].name)}
                      </span>
                    </animated.div>
                  )}
                </Spring>
                <Spring
                  backgroundColor={
                    this.state.pos[1].top === 60 ? colors.yellow : colors.white
                  }
                  opacity={this.state.pos[1].opacity}
                  top={this.state.pos[1].top}
                >
                  {(styles) => (
                    <animated.div
                      style={{
                        ...styles,
                        position: "absolute",
                        borderRadius: 10,
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {this.t(this.words[this.state.words[1]].name)}
                      </span>
                    </animated.div>
                  )}
                </Spring>
                <Spring
                  backgroundColor={
                    this.state.pos[2].top === 60 ? colors.yellow : colors.white
                  }
                  opacity={this.state.pos[2].opacity}
                  top={this.state.pos[2].top}
                >
                  {(styles) => (
                    <animated.div
                      style={{
                        ...styles,
                        position: "absolute",
                        borderRadius: 10,
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {this.t(this.words[this.state.words[2]].name)}
                      </span>
                    </animated.div>
                  )}
                </Spring>
                <Spring
                  backgroundColor={
                    this.state.pos[3].top === 60 ? colors.yellow : colors.white
                  }
                  opacity={this.state.pos[3].opacity}
                  top={this.state.pos[3].top}
                >
                  {(styles) => (
                    <animated.div
                      style={{
                        ...styles,
                        position: "absolute",
                        borderRadius: 10,
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {this.t(this.words[this.state.words[3]].name)}
                      </span>
                    </animated.div>
                  )}
                </Spring>
                <Spring
                  backgroundColor={
                    this.state.pos[4].top === 60 ? colors.yellow : colors.white
                  }
                  opacity={this.state.pos[4].opacity}
                  top={this.state.pos[4].top}
                >
                  {(styles) => (
                    <animated.div
                      style={{
                        ...styles,
                        position: "absolute",
                        borderRadius: 10,
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {this.t(this.words[this.state.words[4]].name)}
                      </span>
                    </animated.div>
                  )}
                </Spring>
                <Spring
                  backgroundColor={
                    this.state.pos[5].top === 60 ? colors.yellow : colors.white
                  }
                  opacity={this.state.pos[5].opacity}
                  top={this.state.pos[5].top}
                >
                  {(styles) => (
                    <animated.div
                      style={{
                        ...styles,
                        position: "absolute",
                        borderRadius: 10,
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {this.t(this.words[this.state.words[5]].name)}
                      </span>
                    </animated.div>
                  )}
                </Spring>
                <Spring
                  backgroundColor={
                    this.state.pos[6].top === 60 ? colors.yellow : colors.white
                  }
                  opacity={this.state.pos[6].opacity}
                  top={this.state.pos[6].top}
                >
                  {(styles) => (
                    <animated.div
                      style={{
                        ...styles,
                        position: "absolute",
                        borderRadius: 10,
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {this.t(this.words[this.state.words[6]].name)}
                      </span>
                    </animated.div>
                  )}
                </Spring>
              </div>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginLeft: 30,
                  width: 100,
                  height: 150,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: this.state.windowWidth < 600 ? 50 : 100,
                    height: 150,
                    backgroundColor: colors.black,
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: this.state.windowWidth < 600 ? 20 : 35,
                      zIndex: 1000,
                      color: colors.white,
                    }}
                  >
                    {this.words[this.getIndexPercent()].percent}%
                  </span>

                  <Spring
                    height={
                      (this.words[this.getIndexPercent()].percent / 100) * 150
                    }
                  >
                    {(styles) => (
                      <animated.div
                        style={{
                          ...styles,
                          position: "absolute",
                          left: 0,
                          bottom: 0,
                          width: this.state.windowWidth < 600 ? 50 : 100,
                          backgroundColor: colors.purple,
                          borderRadius: 10,
                        }}
                      ></animated.div>
                    )}
                  </Spring>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: this.state.windowWidth < 600 ? "100%" : "50%",
              marginTop: "7%",

              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.windowWidth >= 600 && (
              <HighLightTextComponent
                margin={20}
                text={this.t("title1.label")}
                highLightIdx={this.t("title1.label.indexHighlight")}
                textColor={colors.black}
                bgColor={colors.yellow}
              ></HighLightTextComponent>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: this.state.windowWidth < 600 ? "column" : "row",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: this.state.windowWidth < 600 ? "100%" : "50%",
            }}
          >
            <HighLightTextComponent
              margin={20}
              text={this.t("questionStats.label")}
              highLightIdx={this.t("questionStats.label.indexHighlight")}
              textColor={colors.black}
              bgColor={colors.yellow}
            ></HighLightTextComponent>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: this.state.windowWidth < 600 ? 20 : 0,
              width: this.state.windowWidth < 600 ? "100%" : "50%",
            }}
          >
            <p
              style={{
                color: colors.black,
                padding: 0,
                margin: 0,
                fontFamily: "Goldman_bold",
              }}
            >
              {this.t("titleBot.label")}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <input
                placeholder={this.t("placeHolderBot.label")}
                style={{
                  fontSize: 16,
                  height: 40,
                  fontFamily: "Goldman_medium",
                  width: "80%",
                  borderWidth: 0,
                  borderBottomWidth: 4,
                  borderBottomColor: colors.purple,
                  outline: "none",
                }}
                type="text"
                value={this.state.value}
                onChange={this.handleChange.bind(this)}
              />
              {!this.state.success && (
                <div
                  onClick={this.askGPT.bind(this)}
                  style={{
                    display: "flex",
                    backgroundColor: colors.yellow,
                    height: 40,
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 10,
                    marginLeft: 10,
                  }}
                >
                  {!this.state.loading ? (
                    <p
                      style={{
                        fontFamily: "Goldman_bold",
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        color: colors.black,
                      }}
                    >
                      {this.t("botButton.label")}
                    </p>
                  ) : (
                    <BeatLoader color={colors.white}></BeatLoader>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.renderResponse()}

        {this.state.success ? (
          <div style={{ width: this.state.windowWidth < 600 ? "100%" : "30%" }}>
            <ButtonComponent
              text={this.t("buttonStats.label")}
              color={colors.yellow}
            ></ButtonComponent>
          </div>
        ) : this.state.error ? (
          <p
            style={{
              fontFamily: "Goldman_bold",
              padding: 0,
              margin: 0,
              fontSize: 14,
              color: colors.black,
            }}
          >
            {this.t("errorBot.label")}
          </p>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(StatsDivComponent);
