import React from 'react';
import { Spring, animated } from 'react-spring';
import colors from '../../colors';
import HighLightTextComponent from '../highlightText/hightLightTextComponent';
import { withTranslation } from 'react-i18next';
class FinanceDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { animation: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.isInViewport = this.isInViewport.bind(this);
    this.contentRef = React.createRef();
    const t = this.props.t;
    this.t = t;
  }
  componentDidUpdate() {
    if (window.outerWidth !== this.state.windowWidth) {
      this.setState({ windowWidth: window.outerWidth });
    }
  }
  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }
  isInViewport(offset = 0) {
    if (!this.contentRef.current) return false;
    const top = this.contentRef.current.getBoundingClientRect().top;

    if (top + offset >= 0 && top - offset <= window.innerHeight) {
      this.setState({ animation: true });
    } else {
      this.setState({ animation: false });
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', () => this.isInViewport());
  }
  render() {
    return (
      <div
        ref={this.contentRef}
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          marginTop: 50,

          flexDirection: this.state.windowWidth < 600 ? 'column' : 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: this.state.windowWidth < 600 ? '100%' : '50%',
            justifyContent: 'center',
            marginBottom: this.state.windowWidth < 600 ? 20 : 0,
          }}
        >
          <div style={{ flexDirection: 'column' }}>
            <HighLightTextComponent
              text={this.t('funding.label')}
              highLightIdx={this.t('funding.label.indexHighlight')}
              textColor={colors.black}
              bgColor={colors.yellow}
            ></HighLightTextComponent>
            <p
              style={{
                margin: 0,
                marginTop: 10,
                marginBottom: 10,
                textJustify: 'auto',
                color: colors.black,
                fontFamily: 'Goldman_medium',
              }}
            >
              {this.t('funding2.label')}
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: this.state.windowWidth < 600 ? '100%' : '50%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',

              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                top: -20,
                left: 0,
                position: 'absolute',
              }}
            >
              {this.t('funding3.label')}
            </span>
            <Spring
              top={!this.state.animation ? 0 : -5}
              left={!this.state.animation ? 0 : 5}
            >
              {(styles) => (
                <animated.span
                  style={{
                    ...styles,
                    position: 'absolute',
                    color: colors.blue,
                    fontSize: 65,
                    zIndex: -1,
                  }}
                >
                  50%
                </animated.span>
              )}
            </Spring>
            <span style={{ fontSize: 65 }}>50%</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FinanceDivComponent);
