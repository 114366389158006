import React from 'react';
import { animated, Spring } from 'react-spring';
import colors from '../../colors';
import { withTranslation } from 'react-i18next';


class LegalDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
    };
    this.contentRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    const { t } = this.props;
    this.t = t;
  }

  updateDimensions() {
    this.setState({
      width: this.contentRef.current.getBoundingClientRect().width,
      height: this.contentRef.current.getBoundingClientRect().height,
    });
  }
  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
      width: this.contentRef.current.offsetWidth,
      height: this.contentRef.current.offsetHeight,
    });
  }
  handleScroll(offset = 0) {
    if (this.state.windowWidth < 600) {
      if (!this.contentRef.current) return false;
      const top = this.contentRef.current.getBoundingClientRect().top;

      if (top + offset >= 0 && top - offset <= window.innerHeight) {
        this.setState({ animation: true });
      }
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();

    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', () => this.handleScroll());
  }



  componentDidUpdate() {
    if (window.outerWidth !== this.state.windowWidth) {
      this.setState({ windowWidth: window.outerWidth });
    }
    if (
      this.contentRef.current.getBoundingClientRect().width !==
      this.state.width ||
      this.contentRef.current.getBoundingClientRect().height !==
      this.state.height
    ) {
      this.setState({
        width: this.contentRef.current.getBoundingClientRect().width,
        height: this.contentRef.current.getBoundingClientRect().height,
      });
    }
  }

  render() {
    return (
      <div
        ref={this.contentRef}
        style={{
          borderRadius: 10,
          display: 'grid',
          gridTemplateColumns: '1fr',
        }}
        onMouseOver={() =>
          this.setState({
            width: this.contentRef.current.offsetWidth,
            height: this.contentRef.current.offsetHeight,
            animation: true,
          })
        }
        onMouseOut={() =>
          this.state.windowWidth >= 600 && this.setState({ animation: false })
        }
      >
        <div style={{ position: 'relative' }}>
          <Spring
            top={this.state.animation ? -8 : 0}
            left={this.state.animation ? 8 : 0}
            backgroundColor={this.state.animation ? '#ffe82c' : '#b599db'}
          >
            {(styles) => (
              <animated.div
                style={{
                  ...styles,
                  height: this.state.height,
                  width: this.state.width,
                  position: 'absolute',
                  borderRadius: 10,
                }}
              ></animated.div>
            )}
          </Spring>
        </div>
        <div
          style={{
            display: 'flex',
            width:
              this.state.windowWidth < 600
                ? 0.94 * this.state.windowWidth
                : 0.8 * this.state.windowWidth,
            color: 'white',
            borderRadius: 10,
            backgroundColor: colors.black,
            padding: '5%',
            paddingLeft: '12%',
            paddingRight: '8%',
            boxSizing: 'border-box',
            justifyContent: 'center',
            flexDirection: 'column',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center'
            }}
          >
            <h3 >{this.t('slegal.label')}</h3></div>

          <h3>{this.t('definitions.label')}</h3>

          <p>{this.t('def1.label')}</p>
          <p>{this.t('def2.label')}</p>
          <p>{this.t('def3.label')}</p>
          <p>{this.t('def4.label')}</p>
          <p>{this.t('def5.label')}</p>
          <p>{this.t('def6.label')}</p>
          <p>{this.t('def7.label')}</p>

          <h3>{this.t('presentationleg.label')}</h3>

          <p>{this.t('pres1.label')}  </p>
          <p>{this.t('pres2.label')}<br />
             {this.t('pres3.label')}<br />
             {this.t('pres4.label')}<br />
             {this.t('pres5.label')}<br />
             {this.t('pres6.label')}<br />
             {this.t('pres7.label')}<br />
          </p>

          <div ng-bind-html="linkHTML"><p>{this.t('pres8.label')}</p></div>

          <h3>{this.t('conditions.label')}</h3>
          
          <p>{this.t('conditions1.label')}</p>
          <p>{this.t('conditions2.label')}</p>
          <p>{this.t('conditions3.label')}</p>
          <p>{this.t('conditions4.label')}</p>

          <h3>{this.t('descriptionleg.label')}</h3>

          <p>{this.t('descriptionleg1.label')}</p>
          <p>{this.t('descriptionleg2.label')}</p>

          <h3>{this.t('limitations.label')}</h3>

          <p>{this.t('limitations1.label')}</p>
          <p>{this.t('limitations2.label')}</p>
          <p>{this.t('limitations3.label')}</p>

          <h3>{this.t('intproperty.label')}</h3>

          <p>{this.t('intproperty1.label')}</p>
          <p>{this.t('intproperty2.label')}</p>

          <h3>{this.t('liability.label')}</h3>

          <p>{this.t('liability1.label')}</p>
          <p>{this.t('liability2.label')}</p>
          <p>{this.t('liability3.label')}</p>

          <h3>{this.t('personaldata.label')}</h3>

          <p>{this.t('personaldata1.label')}</p>

          <h4>{this.t('personaldata71.label')}</h4>

          <p>{this.t('personaldata711.label')}</p>
          <p>{this.t('personaldata712.label')}</p>

          <h4>{this.t('personaldata72.label')}</h4>

          <p>{this.t('personaldata721.label')}</p>

          <ul>

            <li>{this.t('personaldata722.label')}</li>
            <li>{this.t('personaldata723.label')}</li>
            <li>{this.t('personaldata724.label')}</li>
            <li>{this.t('personaldata725.label')}</li>
            <li>{this.t('personaldata726.label')}</li>


          </ul>

          <p>{this.t('personaldata727.label')}</p>

          <h4>{this.t('personaldata73.label')}</h4>

          <p>
          {this.t('personaldata731.label')} </p>

          <ul>

            <li>{this.t('personaldata732.label')}</li>
            <li>{this.t('personaldata733.label')}</li>
            <li>{this.t('personaldata734.label')}</li>
            <li>{this.t('personaldata735.label')}</li>
            <li>{this.t('personaldata736.label')}</li>
            <li>{this.t('personaldata737.label')}</li>
          </ul>

          <p>{this.t('personaldata738.label')}</p>

          <p>{this.t('personaldata739.label')}</p>
          {this.t('personaldata7310.label')}<br/>
          {this.t('personaldata7311.label')}
          <p>{this.t('personaldata7312.label')}</p>

          <p>{this.t('personaldata7313.label')}</p>

          <h4>{this.t('personaldata74.label')}</h4>

          <p>{this.t('personaldata741.label')}</p>
          <p>{this.t('personaldata742.label')}</p>
          <p>{this.t('personaldata743.label')}</p>
          <p>{this.t('personaldata744.label')}</p>

          <div ng-bind-html="rgpdHTML"></div>


          <h3>{this.t('incidents.label')}</h3>
          <p>{this.t('incidents1.label')}</p>
          <p>{this.t('incidents2.label')}</p>

          <h4>{this.t('security.label')}</h4>

          <p>{this.t('security1.label')}</p>
          <p>{this.t('security2.label')}</p>

          <h3>{this.t('links.label')}</h3>

          <p>{this.t('links1.label')}</p>
          <p>{this.t('links2.label')}</p>
          <p></p>

          <h4>{this.t('cookies.label')}</h4>
          <p>{this.t('cookies1.label')}</p>
          <p>{this.t('cookies2.label')}</p>
          <p>{this.t('cookies3.label')}</p>
          <p>{this.t('cookies4.label')}</p>
          <p>{this.t('cookies5.label')}</p>
          <p>{this.t('cookies6.label')}</p>
          <p>{this.t('cookies7.label')}</p>
          <p>{this.t('cookies8.label')}</p>

          <h4>{this.t('tags.label')}</h4>

          <p>{this.t('tags1.label')}</p>
          <p>{this.t('tags2.label')}</p>
          <p>{this.t('tags3.label')}</p>
          <p>{this.t('tags4.label')}</p>
          <p>
          </p>
          
          <h3>{this.t('law.label')}</h3>
          <p>{this.t('law1.label')}</p>




        </div>
      </div>
    );
  }
}

export default withTranslation()(LegalDivComponent);
