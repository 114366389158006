import './App.css';
import React from 'react';
import NavBarComponent from './components/navbar/NavBarComponent';

import HomeScreen from './screens/HomeScreen';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProjectScreen from './screens/ProjectsScreen';
import LegalScreen from './screens/LegalScreen';
import ContactScreen from './screens/ContactScreen';
import MapScreen from './screens/MapScreen';
import BlogScreen from './screens/BlogScreen';
import { Helmet } from 'react-helmet';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Radiant</title>
          <meta name='description' content='test on react-helmet' />
          <meta name='theme-color' content='#ccc' />
        </Helmet>

        <Router>
          <div className='appContainer'>
            <div className='container'>
              <div
                style={{
                  maxWidth: this.state.windowWidth < 600 ? '94%' : '80%',
                }}
                className='rowContainerNavBar'
              >
                <NavBarComponent color='red'></NavBarComponent>
              </div>
            </div>
            <Routes>
              <Route path='/' element={<HomeScreen />}></Route>
              <Route path='/projects' element={<ProjectScreen />}></Route>
              <Route path='/legal' element={<LegalScreen />}></Route>
              <Route path='/contact' element={<ContactScreen />}></Route>
              <Route path='/map' element={<MapScreen />}></Route>
              <Route path='/blog' element={<BlogScreen />}></Route>
            </Routes>
          </div>
        </Router>
      </>
    );
  }
}

export default App;
