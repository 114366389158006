import React from 'react';
import { animated, Spring } from 'react-spring';
import colors from '../../colors';
import HighLightTextComponent from '../highlightText/hightLightTextComponent';
import { withTranslation } from 'react-i18next';
class EthicsDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
      visible: false,
    };
    this.contentRef = React.createRef();
    this.isInViewport = this.isInViewport.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    const { t } = this.props;
    this.t = t;
  }

  isInViewport() {
    if (!this.contentRef.current) return false;
    const top = this.contentRef.current.getBoundingClientRect().top;

    if (top >= 0 && top <= window.innerHeight / 2) {
      this.setState({ visible: true });
    }
  }
  updateDimensions() {
    this.setState({
      width: this.contentRef.current.getBoundingClientRect().width,
      height: this.contentRef.current.getBoundingClientRect().height,
    });
  }
  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }
  handleScroll(offset = 0) {
    if (this.state.windowWidth < 600) {
      if (!this.contentRef.current) return false;
      const top = this.contentRef.current.getBoundingClientRect().top;

      if (top + offset >= 0 && top - offset <= window.innerHeight) {
        this.setState({ animation: true });
      }
    }
  }
  componentDidMount() {
    this.updateWindowDimensions();

    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateWindowDimensions);

    window.addEventListener('scroll', () => this.isInViewport());
    window.addEventListener('scroll', () => this.handleScroll());
  }
  componentDidUpdate() {
    if (window.outerWidth !== this.state.windowWidth) {
      this.setState({ windowWidth: window.outerWidth });
    }
    if (
      this.contentRef.current.getBoundingClientRect().width !==
        this.state.width ||
      this.contentRef.current.getBoundingClientRect().height !==
        this.state.height
    ) {
      this.setState({
        width: this.contentRef.current.getBoundingClientRect().width,
        height: this.contentRef.current.getBoundingClientRect().height,
      });
    }
  }
  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div
          ref={this.contentRef}
          style={{
            borderRadius: 10,
            display: 'grid',
            gridTemplateColumns: '1fr',
          }}
          onMouseOver={() =>
            this.setState({
              width: this.contentRef.current.offsetWidth,
              height: this.contentRef.current.offsetHeight,
              animation: true,
            })
          }
          onMouseOut={() =>
            this.state.windowWidth >= 600 && this.setState({ animation: false })
          }
        >
          <div style={{ position: 'relative' }}>
            <Spring
              top={this.state.animation ? -8 : 0}
              left={this.state.animation ? 8 : 0}
              backgroundColor={
                this.state.animation ? colors.purple : colors.blue
              }
            >
              {(styles) => (
                <animated.div
                  style={{
                    ...styles,
                    height: this.state.height,
                    width: this.state.width,
                    position: 'absolute',
                    borderRadius: 10,
                  }}
                ></animated.div>
              )}
            </Spring>
          </div>

          <div
            ref={this.contentRef}
            style={{
              display: 'flex',
              width:
                this.state.windowWidth < 600
                  ? this.state.windowWidth * 0.94
                  : this.state.windowWidth * 0.8,
              color: 'white',
              borderRadius: 10,
              backgroundColor: colors.black,
              alignItems: 'center',
              flexDirection: 'column',
              padding: '5%',
              paddingLeft: '12%',
              paddingRight: '8%',
              boxSizing: 'border-box',
              justifyContent: 'space-between',
              zIndex: 1000,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                marginBottom: this.state.windowWidth < 600 ? 30 : 80,
                flexDirection: this.state.windowWidth < 600 ? 'column' : 'row',

                flex: 1,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <HighLightTextComponent
                  text={this.t('title5.label')}
                  highLightIdx={this.t('title5.label.indexHighlight')}
                  textColor={colors.white}
                  bgColor={colors.purple}
                ></HighLightTextComponent>
                <p
                  style={{
                    fontFamily: 'Goldman_medium',
                    fontSize: this.state.windowWidth < 600 ? 'medium' : 'large',
                    marginBottom: this.state.windowWidth < 600 ? 40 : 0,
                    margin: 0,
                    marginTop: 10,
                    textJustify: 'auto',
                  }}
                >
                  {this.t('safety.label')}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  marginTop: '2%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={require('../../assets/lock.webp')}
                  alt='Animation cadenas'
                  style={{
                    objectFit: 'contain',
                    width:
                      this.state.windowWidth < 600
                        ? this.state.width / 3
                        : this.state.width / 6,
                  }}
                ></img>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                backgroundColor: colors.black,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',

                flexDirection: this.state.windowWidth < 1000 ? 'column' : 'row',
              }}
            >
              <div
                style={{
                  width: this.state.windowWidth < 1000 ? '100%' : '50%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    flexDirection: 'column',
                    flexGrow: 1,
                    alignItems: 'center',
                    textAlign: 'center',
                    boxSizing: 'border-box',
                    justifyContent: 'space-between',
                  }}
                >
                  <img
                    alt='Logo OVH'
                    style={{ width: '50%' }}
                    src={require('../../assets/logo_ovh.webp')}
                  ></img>
                  <p
                    style={{
                      fontFamily: 'Goldman_medium',
                      fontSize:
                        this.state.windowWidth < 600 ? 'medium' : 'large',
                      margin: 0,
                      marginTop: 10,
                      marginBottom: 10,
                      textJustify: 'auto',
                    }}
                  >
                    {this.t('ovh.label')}
                  </p>
                </div>
              </div>
              <div
                style={{
                  paddingTop: '2%',
                  width: this.state.windowWidth < 1000 ? '100%' : '50%',
                  textAlign: 'center',
                  flex: '1',
                  height: 'auto',
                }}
              >
                <img
                  style={{ width: '50%' }}
                  alt='Logo NetExplorer'
                  src={require('../../assets/logo_netexplorer.webp')}
                ></img>
                <p
                  style={{
                    fontFamily: 'Goldman_medium',
                    fontSize: this.state.windowWidth < 600 ? 'medium' : 'large',
                    margin: 0,
                    marginTop: 10,
                    marginBottom: 10,
                    textJustify: 'auto',
                  }}
                >
                  {this.t('netexplorer.label')}
                </p>
              </div>
            </div>

            <div></div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                marginBottom: 20,
                flexDirection: this.state.windowWidth < 600 ? 'column' : 'row',
              }}
            >
              {this.state.windowWidth >= 600 && (
                <div
                  style={{
                    display: 'flex',
                    marginTop: '8%',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={require('../../assets/human.webp')}
                    alt='Animation humain'
                    style={{
                      objectFit: 'contain',
                      width:
                        this.state.windowWidth < 600
                          ? this.state.width / 3
                          : this.state.width / 6,
                    }}
                  ></img>
                </div>
              )}

              <div
                style={{
                  marginTop: '8%',
                  width: '100%',
                  height: '100%',
                }}
              >
                <div>
                  <HighLightTextComponent
                    text={this.t('title6.label')}
                    highLightIdx={this.t('title6.label.indexHighlight')}
                    textColor={colors.white}
                    bgColor={colors.purple}
                  ></HighLightTextComponent>
                </div>

                <p
                  style={{
                    margin: 0,
                    marginTop: 10,
                    marginBottom: this.state.windowWidth < 600 ? 40 : 0,
                    textJustify: 'auto',
                    fontFamily: 'Goldman_medium',
                  }}
                >
                  {this.t('ethics1.label')}
                </p>
                <p
                  style={{
                    paddingTop: '4%',
                    margin: '0px',
                  }}
                >
                  {this.t('ethics2.label')}
                </p>
                <p
                  style={{
                    paddingTop: '4%',
                    margin: '0px',
                  }}
                >
                  {this.t('ethics3.label')}
                </p>
              </div>
              {this.state.windowWidth < 600 && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={require('../../assets/human.webp')}
                    alt='Animation humain'
                    width={
                      this.state.windowWidth < 600
                        ? this.state.width / 3
                        : this.state.width / 4
                    }
                  ></img>
                </div>
              )}
            </div>
          </div>
        </div>

        <Spring height={this.state.visible ? '40%' : '0%'} delay={0}>
          {(styles) => (
            <animated.div
              style={{
                ...styles,
                display: 'flex',
                position: 'absolute',
                top: 0,
                zIndex: 2000,
                left: '2%',
                width: this.state.windowWidth < 600 ? '2%' : '1%',
                flex: 1,
                backgroundColor: '#41479B',
                borderBottomLeftRadius: 10,
              }}
            ></animated.div>
          )}
        </Spring>
        <Spring height={this.state.visible ? '40%' : '0%'} delay={150}>
          {(styles) => (
            <animated.div
              style={{
                ...styles,
                display: 'flex',
                position: 'absolute',
                top: 0,
                left: this.state.windowWidth < 600 ? '4%' : '3%',
                width: this.state.windowWidth < 600 ? '2%' : '1%',
                flex: 1,
                zIndex: 2000,
                backgroundColor: colors.white,
              }}
            ></animated.div>
          )}
        </Spring>
        <Spring height={this.state.visible ? '40%' : '0%'} delay={300}>
          {(styles) => (
            <animated.div
              style={{
                ...styles,
                display: 'flex',
                position: 'absolute',
                top: 0,
                left: this.state.windowWidth < 600 ? '6%' : '4%',
                width: this.state.windowWidth < 600 ? '2%' : '1%',
                flex: 1,
                zIndex: 2000,
                backgroundColor: '#FF4B55',
                borderBottomRightRadius: 10,
              }}
            ></animated.div>
          )}
        </Spring>
      </div>
    );
  }
}

export default withTranslation()(EthicsDivComponent);
