import React from 'react';
import colors from '../../colors';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class FooterDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
      visible: false,
    };
    const { t } = this.props;
    this.t = t;
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          backgroundColor: colors.black,
          justifyContent: 'center',
          flexDirection: this.props.windowWidth < 600 ? 'column' : 'row',
        }}
      >
        <div
          style={{
            borderRight: '1px solid gray',
            borderBottom: '1px solid gray',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              color: 'white',
              flexGrow: 1,
              alignItems: 'center',
              textAlign: 'center',
              boxSizing: 'border-box',
              justifyContent: 'space-between',
            }}
          >
            <img
              style={{ width: '40%' }}
              alt='Logo Radiant'
              src={require('../../assets/logo_resized.webp')}
            ></img>

            <h3
              style={{
                fontFamily: 'Goldman_medium',
                fontSize: this.state.windowWidth < 600 ? 'medium' : 'large',
              }}
            >
              59 Rue de Ponthieu <br />
              Bureau 321 <br />
              75008 PARIS <br />
              <br />
              07 82 72 06 87
            </h3>
          </div>
        </div>
        <div
          style={{
            color: 'white',
            paddingTop: '2%',
            borderRight: '1px solid gray',
            borderBottom: '1px solid gray',

            width: '100%',
          }}
        >
          <h3
            style={{
              fontFamily: 'Goldman_medium',
              fontSize: this.state.windowWidth < 600 ? 'medium' : 'large',
            }}
          >
            <center>{this.t('sitemap.label')}</center>
            <ul>
              <br />
              <Link to='/'>
                <li>{this.t('shome.label')} </li>
              </Link>

              {/* <Link to="/projects"> <li>Réalisations </li></Link> */}

              <Link to='/contact'>
                <li>{this.t('scontact.label')}</li>
              </Link>

              {/* <Link to="/blog"> <li>Blog</li></Link> */}

              <Link to='/legal'>
                <li>{this.t('slegal.label')}</li>
              </Link>
            </ul>
          </h3>
        </div>
        <div
          style={{
            paddingTop: '2%',
            width: '100%',
            display: 'flex',
            color: 'white',
            textAlign: 'center',
            borderBottom: '1px solid gray',

            flexDirection: 'column',
          }}
        >
          <h3
            style={{
              fontFamily: 'Goldman_medium',
              fontSize: this.state.windowWidth < 600 ? 'medium' : 'large',
            }}
          >
            <Link to='/legal'>{this.t('slegal.label')}</Link>
          </h3>
          <br />
          <div
            style={{
              textAlign: 'center',
              marginTop: 'auto',
            }}
          >
            <p
              style={{
                fontFamily: 'Goldman_medium',
                margin: 0,
                marginBottom: 10,
                fontSize: this.state.windowWidth < 600 ? 'small' : 'medium',
              }}
            >
              Copyright © 2022 - Radiant SAS
            </p>
            <p
              style={{
                margin: 0,
                marginBottom: 10,

                fontFamily: 'Goldman_medium',
                fontSize: this.state.windowWidth < 600 ? 'small' : 'medium',
              }}
            >
              Made with{' '}
              <span role='img' aria-label='heart'>
                ❤️
              </span>{' '}
              by Radiant Team <br />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FooterDivComponent);
