import React from 'react';
import colors from '../../colors';
import { animated, Spring } from 'react-spring';
import { withTranslation } from 'react-i18next';

import {
  CaretForwardOutline,
  CaretBackOutline,
  CloseOutline,
} from 'react-ionicons';

class ActionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { animation: false };
  }

  render() {
    return (
      <div
        onMouseOver={() => this.setState({ animation: true })}
        onMouseOut={() => this.setState({ animation: false })}
        style={{
          display: 'flex',
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <Spring
          top={this.state.animation ? -3 : 0}
          left={this.state.animation ? 3 : 0}
        >
          {(styles) => (
            <animated.div style={{ ...styles, position: 'absolute' }}>
              {this.props.type === 'next' ? (
                <CaretForwardOutline
                  color={colors.blue}
                  width={'100%'}
                  height={'100%'}
                ></CaretForwardOutline>
              ) : null}
              {this.props.type === 'prev' ? (
                <CaretBackOutline
                  color={colors.blue}
                  width={'100%'}
                  height={'100%'}
                ></CaretBackOutline>
              ) : null}
              {this.props.type === 'close' ? (
                <CloseOutline
                  color={colors.blue}
                  width={'100%'}
                  height={'100%'}
                ></CloseOutline>
              ) : null}
            </animated.div>
          )}
        </Spring>
        <div style={{ position: 'absolute' }}>
          {this.props.type === 'next' ? (
            <CaretForwardOutline
              width={'100%'}
              height={'100%'}
              color={colors.yellow}
            ></CaretForwardOutline>
          ) : null}
          {this.props.type === 'prev' ? (
            <CaretBackOutline
              color={colors.yellow}
              width={'100%'}
              height={'100%'}
            ></CaretBackOutline>
          ) : null}
          {this.props.type === 'close' ? (
            <CloseOutline
              color={colors.yellow}
              width={'100%'}
              height={'100%'}
            ></CloseOutline>
          ) : null}
        </div>
      </div>
    );
  }
}

class SubProjectDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    const { t } = this.props;
    this.t = t;
  }

  renderList() {
    let list = [];
    for (let i = 0; i < this.props.list.length; i++) {
      list.push(
        <p
          key={this.props.list[i]}
          style={{ margin: 0, marginBottom: 3, fontSize: 14 }}
        >
          <li>{this.t(this.props.list[i])}</li>
        </p>
      );
    }
    return list;
  }

  renderTags() {
    let tags = [];
    for (let i = 0; i < this.props.tags.length; i++) {
      tags.push(
        <span
          key={i + 'span'}
          style={{
            fontSize: 10,
            backgroundColor: this.props.tags[i].color,
            borderRadius: 10,
            color: colors.white,
            padding: 2,
            margin: 0,

            paddingLeft: 5,
            paddingRight: 5,
            marginRight: 5,
            marginBottom: 10,
          }}
        >
          {this.t(this.props.tags[i].text)}
        </span>
      );
    }
    return tags;
  }
  render() {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          flexDirection: this.props.windowWidth < 600 ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'space-between',
          position: 'relative',
        }}
      >
        <div
          onClick={() => this.props.next()}
          style={{
            position: 'absolute',
            right: this.props.windowWidth < 600 ? -20 : -60,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,

            elevation: 5,
            top: this.props.windowWidth < 600 ? -20 : '50%',
            height: this.props.windowWidth < 600 ? 40 : 50,
            aspectRatio: 1,
            cursor: 'pointer',
          }}
        >
          <ActionComponent type={'next'}></ActionComponent>
        </div>
        <div
          onClick={() => this.props.prev()}
          style={{
            position: 'absolute',
            left: this.props.windowWidth < 600 ? -20 : -60,
            top: this.props.windowWidth < 600 ? -20 : '50%',
            height: this.props.windowWidth < 600 ? 40 : 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            aspectRatio: 1,
            cursor: 'pointer',
          }}
        >
          <ActionComponent type={'prev'}></ActionComponent>
        </div>
        <div
          style={{
            display: 'flex',
            width: this.props.windowWidth < 600 ? '100%' : '60%',

            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div style={{}}>
            <div>
              <h4
                style={{
                  color: colors.white,
                  marginBottom: 10,
                  marginTop: 20,
                }}
              >
                {this.t(this.props.title)}
              </h4>
            </div>
            <div>{this.renderList()}</div>
          </div>
          <div style={{ marginTop: 10 }}>{this.renderTags()}</div>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: this.props.windowWidth < 600 ? '100%' : '40%',
            flexDirection: 'column',
            justifyContent:
              this.props.windowWidth < 600 ? 'flex-end' : 'flex-start',
            alignItems: 'center',
            marginTop: 20,
            marginLeft: this.props.windowWidth < 600 ? 0 : 10,
          }}
        >
          {this.props.image !== '' ? (
            <img
              style={{
                borderRadius: 10,
                width: '100%',
                objectFit: 'fill',

                overflow: 'hidden',
              }}
              src={this.props.image}
            ></img>
          ) : null}
          {this.props.video !== '' ? (
            <video
              autoPlay={true}
              style={{
                borderRadius: 10,
                width: '100%',
              }}
              src={this.props.video}
            ></video>
          ) : null}
        </div>
      </div>
    );
  }
}

const SubProjectDivComponentT = withTranslation()(SubProjectDivComponent);

class ProjectDivComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.interval = null;
    const { t } = this.props;
    this.t = t;
  }

  renderTags() {
    let tags = [];
    for (let i = 0; i < this.props.data.mainTags.length; i++) {
      tags.push(
        <span
          key={i + 'span2'}
          style={{
            backgroundColor: this.props.data.mainTags[i].color,
            borderRadius: 10,
            color: colors.white,
            padding: 2,
            paddingLeft: 5,
            paddingRight: 5,
            marginRight: 10,
            marginBottom: 10,
            fontSize: 14,
          }}
        >
          {this.t(this.props.data.mainTags[i].text)}
        </span>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '50%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {tags}
      </div>
    );
  }

  next() {
    let selected = this.state.selected;
    if (selected === this.props.data.subProjects.length - 1) {
      selected = 0;
    } else {
      selected += 1;
    }
    this.setState({ selected: selected });
  }

  prev() {
    let selected = this.state.selected;
    if (selected === 0) {
      selected = this.props.data.subProjects.length - 1;
    } else {
      selected -= 1;
    }
    this.setState({ selected: selected });
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth * 0.98,
      windowHeight: window.innerHeight,
    });
  }
  renderPoints() {
    let points = [];
    let i = 0;
    this.props.data.subProjects.forEach((element) => {
      points.push(
        <Spring
          key={'element_' + i}
          id={i + '_points'}
          backgroundColor={
            i === this.state.selected ? colors.yellow : colors.purple
          }
          width={i === this.state.selected ? 15 : 10}
          height={i === this.state.selected ? 15 : 10}
          borderRadius={i === this.state.selected ? 15 / 2 : 10 / 2}
        >
          {(styles) => (
            <animated.div
              style={{
                ...styles,
                margin: 3,
              }}
            ></animated.div>
          )}
        </Spring>
      );
      i = i + 1;
    });
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>{points}</div>
    );
  }
  render() {
    return (
      <Spring
        marginTop={this.props.deploy && this.state.windowWidth < 600 ? 20 : 0}
        // height={
        //   //this.props.deploy ? (this.state.windowWidth < 600 ? 1000 : 600) : 0
        //   this.props.deploy
        //     ? this.state.windowWidth < 600
        //       ? this.props.data.citation != ''
        //         ? undefined
        //         : undefined
        //       : this.props.data.citation != ''
        //       ? undefined
        //       : undefined
        //     : 0
        // }
        opacity={this.props.deploy ? 1 : 0}
        //width={this.props.deploy ? '100%' : '0%'}
      >
        {(styles) => (
          <animated.div
            // className={'gradient-background'}
            style={{
              ...styles,
              background:
                'linear-gradient(230deg,' +
                this.props.data.colorAnim1 +
                ' 0%,' +
                this.props.data.colorAnim2 +
                ' 90%, rgba(255,232,44,1) 100%)',
              backgroundSize: '120% 120%',
              animation: 'gradient-animation 12s ease infinite',
              width: '100%',
              height: this.props.deploy ? undefined : 0,

              justifyContent: 'center',
              flexDirection: 'column',
              //backgroundColor: colors.blue,
              //padding: '8%',
              paddingLeft: '8%',
              paddingRight: '8%',
              boxSizing: 'border-box',
              borderRadius: 10,
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            {this.state.windowWidth >= 600 ? (
              <div
                onClick={() => this.props.close(this.props.idSelection)}
                style={{
                  position: 'absolute',
                  right: 20,
                  top: 20,
                  height: 40,
                  aspectRatio: 1,
                  cursor: 'pointer',
                }}
              >
                <ActionComponent type='close'></ActionComponent>
              </div>
            ) : null}
            <Spring marginTop={this.props.deploy ? '5%' : '-100%'}>
              {(styles) => (
                <animated.div
                  style={{
                    ...styles,
                    display: 'flex',
                    flexDirection:
                      this.state.windowWidth < 600 ? 'column' : 'row',
                    //marginTop: '5%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      width: this.state.windowWidth < 600 ? '100%' : '40%',
                    }}
                  >
                    <h3 style={{ color: colors.white, marginTop: 0 }}>
                      {this.t(this.props.data.mainTitle)}
                    </h3>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: this.state.windowWidth < 600 ? '100%' : '60%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginLeft: this.state.windowWidth < 600 ? 0 : 20,
                    }}
                  >
                    {this.renderTags()}
                    <div
                      style={{
                        display: 'flex',
                        height: '100%',
                        maxHeight: 20,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '50%',
                      }}
                    >
                      <img
                        style={{ width: '50%' }}
                        src={this.props.data.mainLogo}
                      ></img>
                    </div>
                  </div>
                </animated.div>
              )}
            </Spring>
            {/* {this.props.data.citation !== '' ? (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection:
                    this.state.windowWidth < 600 ? 'column' : 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '3%',
                }}
              >
                <div
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 50,
                    marginRight: 20,
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      borderRadius: 50,
                    }}
                    src={this.props.data.profileImg}
                  ></img>
                </div>
                <div
                  style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
                >
                  <div
                    style={{ display: 'flex', flex: 1, position: 'relative' }}
                  >
                    <p>{this.props.data.citation}</p>
                    <span
                      style={{
                        color: colors.yellow,
                        position: 'absolute',
                        top: 0,
                        fontSize: 20,

                        left: 0,
                      }}
                    >
                      "
                    </span>
                    <span
                      style={{
                        color: colors.yellow,
                        fontSize: 20,
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      "
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <p style={{ margin: 0, fontFamily: 'Goldman_bold' }}>
                      {this.t(this.props.data.name)}
                    </p>
                  </div>
                </div>
              </div>
            ) : null} */}

            <div
              style={{
                height: 1,
                backgroundColor: colors.white,

                marginTop: '1%',
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              {this.renderPoints()}
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '5%',
              }}
            >
              <SubProjectDivComponentT
                next={this.next.bind(this)}
                prev={this.prev.bind(this)}
                video={this.props.data.subProjects[this.state.selected].video}
                windowWidth={this.state.windowWidth}
                title={this.props.data.subProjects[this.state.selected].title}
                tags={this.props.data.subProjects[this.state.selected].tags}
                list={this.props.data.subProjects[this.state.selected].list}
                image={this.props.data.subProjects[this.state.selected].image}
              ></SubProjectDivComponentT>
            </div>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export default withTranslation()(ProjectDivComponent);
