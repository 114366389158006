import React from 'react';
import BlogDivComponent from '../components/blogDivComponent/BlogDivComponent';

class BlogScreen extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {};
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    return(
      <div className='container'>

     
        <div
          style={{ maxWidth: this.state.windowWidth < 600 ? '94%' : '80%' }}
          className='rowContainer'
        >
          <BlogDivComponent></BlogDivComponent>
        </div>

    
      </div>
    );
  }
}

export default BlogScreen;
