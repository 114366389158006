const colors = {
  blue: '#92cee9',
  purple: '#b599db',
  yellow: '#ffe82c',
  black: '#171717',
  // black: '#000000',
  white: '#ffffff',
};

export default colors;
