import React from 'react';
import { animated, Spring } from 'react-spring';
import colors from '../../colors';
import { withTranslation } from 'react-i18next';

class HighLightTextComponent2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
      wordIdx: [0, 1, 2],
    };
    this.textRef = React.createRef();
    this.elementRef = React.createRef();
    this.words = ['word1.label', 'word2.label', 'word3.label'];
    this.interval = null;
    const { t } = this.props;
    this.t = t;
  }
  async delay(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }

  componentDidMount() {
    this.interval = setInterval(async () => {
      await this.setState({ animation: true });

      await this.delay(1000);

      await this.setState({ animation: false });
      await this.changeWord();
    }, 4000);
    this.setState({
      width: this.textRef.current.offsetWidth + 5,
      height: this.textRef.current.offsetHeight,
      left: this.elementRef.current.offsetLeft,
      top: this.elementRef.current.offsetTop,
      hightLightWidth: this.elementRef.current.offsetWidth,
      hightLightHeight: this.elementRef.current.offsetHeight,
    });
  }

  resetInterval(interval) {
    clearInterval(interval);
  }

  componentWillUnmount() {
    if (this.interval) {
      this.resetInterval(this.interval);
    }
  }

  async changeWord() {
    let wordsIdx = this.state.wordIdx;
    wordsIdx = this.arrayRotate(wordsIdx, false);
    await this.setState({
      wordsIdx: wordsIdx,
    });
    setTimeout(() => {
      this.setState({
        hightLightWidth: this.elementRef.current.offsetWidth,
        hightLightHeight: this.elementRef.current.offsetHeight,
      });
    }, 200);
  }

  arrayRotate(arr, reverse) {
    if (reverse) arr.unshift(arr.pop());
    else arr.push(arr.shift());
    return arr;
  }

  renderWords() {
    const words = this.t(this.props.text).split(' ');
    let elements = [];
    let i = 0;
    words.forEach((element) => {
      if (this.props.highLightIdx === i.toString()) {
        elements.push(
          <Spring key={element} opacity={this.state.animation ? 0 : 1}>
            {(styles) => (
              <animated.span style={styles} ref={this.elementRef}>
                {this.t(this.words[this.state.wordIdx[0]]) + ' '}
              </animated.span>
            )}
          </Spring>
        );
      } else {
        elements.push(<span key={element}>{element + ' '}</span>);
      }
      i = i + 1;
    });
    this.isWordsRendered = true;
    return elements;
  }

  render() {
    return (
      <div
        style={{
          position: 'relative',

          width: this.state.width,
          height: this.state.height,
        }}
      >
        <Spring
          width={this.state.animation ? this.state.hightLightWidth : 0}
          backgroundColor={
            this.state.animation
              ? colors[Object.keys(colors)[this.state.wordIdx[0]]]
              : colors[Object.keys(colors)[this.state.wordIdx[1]]]
          }
        >
          {(styles) => (
            <animated.div
              style={{
                ...styles,
                height: this.state.hightLightHeight,
                position: 'absolute',
                left: this.state.left,
                top: this.state.top,
                borderRadius: '10px',
              }}
            ></animated.div>
          )}
        </Spring>

        <div
          ref={this.textRef}
          style={{
            fontFamily: 'Goldman_bold',
            fontSize: 'xx-large',
            position: 'absolute',
            color: this.props.textColor,
            bottom: 0,
            left: 0,
            borderRadius: 10,
          }}
        >
          {this.renderWords()}
        </div>
      </div>
    );
  }
}

export default withTranslation()(HighLightTextComponent2);
